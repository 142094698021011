import { Button, Stack } from '@mui/material';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import SignaturePad from 'react-signature-canvas';
import { PlaceHolderTypes, replacePlaceholderwithImage } from 'utils/esign.helper';
import { setPlaceHolderValue } from 'store/mainSlice';
import DrawSignPopup from 'layout/MainLayoutNewUI/Popups/DrawSignPopup';
import CustomSignTemplate from 'app/pages/RoutesPages/AddSignOnCanvas/RightSideSignNPlaceholderValueAdd/SignActionsList/CustomSignTemplate';

const DrawSign = ({ canvasRdxAction = selectReplaceCanvasAction }) => {
  const sigCanvas = useRef(null);
  const canvas = useSelector(canvasRdxAction);
  const dispatch = useDispatch();
  const create = () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    replacePlaceholderwithImage(URL, canvas, (img) => {
      // setPayloadWithUrl((prevState) => {
      //   return [...prevState, { id: img?.id, url: img.toDataURL() }];
      // });
      dispatch(setPlaceHolderValue({ value: { id: img?.id, addedValue: img.toDataURL(), actionType: PlaceHolderTypes.sign } }));
    });
  };

  return (
    <>
      <DrawSignPopup canvasRdxAction={canvasRdxAction} />

      {/* <h6> Draw Here </h6>
      <div className="sigPadContainer">
        <SignaturePad penColor={'black'} canvasProps={{ className: 'sigCanvas relative', height: 125 }} ref={sigCanvas} />
      </div>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
        <Button onClick={() => sigCanvas.current.clear()} sx={{ color: 'red' }}>
          Clear
        </Button>
        <Button onClick={create} variant="outlined">
          Adopt & Sign
        </Button>
      </Stack> */}
    </>
  );
};

export default DrawSign;
