import EsignAppAssets from 'app/assets';
import { Carousel } from 'flowbite-react';
import { useRef } from 'react';
const CarouselItem = ({ title, descrption, content, img }) => {
  return (
    <div className="bg-[white] border-[1px] border-[#D4D4D8] h-[200px] p-[20px] flex flex-col gap-[10px] rounded-[10px] min-w-[350px]">
      <div className="flex items-center gap-[10px]">
        <div className="w-[48px] h-[48px] rounded-full">
          <img src={img} alt={title} className="object-cover w-full h-full rounded-full" />
        </div>
        <div>
          <h5 className="font-[700] text-[16px] text-[#030712]">{title}</h5>
          <p className="text-[#030712] font-[500] text-[16px]">{descrption}</p>
        </div>
      </div>
      <p className="w-[100%] text-[16px] font-[500] text-[#6B7280] overflow-hidden text-ellipsis line-clamp-4">{content}</p>
    </div>
  );
};

const TestimonialsConst = [
  {
    title: 'Deepak Surie',
    descrption: 'India Trading',
    content: 'Switching to E-Sign has saved us countless hours and significantly reduced our paper usage. Highly recommend!',
    img: EsignAppAssets.DeepakSurieImg
  },
  {
    title: 'Pratik Nair',
    descrption: 'Coreyo',
    content: "DigieSign has revolutionized our document workflow. It's fast, secure, and incredibly user-friendly.",
    img: EsignAppAssets.PratikNairImg
  },
  {
    title: 'Dhananjai',
    descrption: 'FPTP',
    content: 'Saved our organisation over a lot of time by automating our entire workflow.  Thanks DigieSign.',
    img: EsignAppAssets.DhananjaiImg
  },
  {
    title: 'Avinash Mehrotra',
    descrption: 'AST',
    content:
      'Adopting DigieSign made our organisation environmentally friendly by eliminating the need for printing, scanning, and mailing.',
    img: EsignAppAssets.AvinashImg
  },
  {
    title: 'Verdant Holidays',
    descrption: 'Verdant',
    content: 'Using DigieSign has streamlined our document signing process significantly.',
    img: EsignAppAssets.VerdantHolidaysImg
  }
];
const TestimonialsCarousel = ({ items }) => {
  const carouselRef = useRef(null);
  let isDragging = false;
  let startX, scrollLeft;

  const handleMouseDown = (e) => {
    isDragging = true;
    carouselRef.current.classList.add('cursor-grabbing');
    startX = e.pageX - carouselRef.current.offsetLeft;
    scrollLeft = carouselRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDragging = false;
    carouselRef.current.classList.remove('cursor-grabbing');
  };

  const handleMouseUp = () => {
    isDragging = false;
    carouselRef.current.classList.remove('cursor-grabbing');
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scroll speed
    carouselRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div
      ref={carouselRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      className="flex overflow-x-scroll gap-[20px] p-[10px] cursor-grab scrollbar-hide"
    >
      {TestimonialsConst.map((item, index) => (
        <CarouselItem key={index} {...item} />
      ))}
    </div>
  );
};
export default TestimonialsCarousel;
