import EsignValueRemoveSignBtn from 'app/components/Buttons/EsignValueRemoveSignBtn';
import CommonDropdown from 'app/components/Dropdowns/CommonDropdown';
import EsignAppInput from 'app/components/Input/EsignAppInput';
import { useAccount } from 'context/AccountProvider';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectUsersNodes, setEsignSelectedSignType } from 'store/esignMainSlice';
import { setPlaceHolderValue } from 'store/mainSlice';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { PlaceHolderTypes, replacePlaceholderwithImage, replaceStylizeSignature } from 'utils/esign.helper';
import CustomSignTemplate from './CustomSignTemplate';
export const fontFamilies = [
  { id: 1, title: 'Stalemate', type: 'Stalemate-Regular', Icon: () => <></> },
  { id: 2, title: 'Caveat-Variable', type: 'Caveat-Variable', Icon: () => <></> },
  { id: 3, title: 'StyleScript', type: 'StyleScript-Regular', Icon: () => <></> },
  { id: 4, title: 'SyneTactile', type: 'SyneTactile-Regular', Icon: () => <></> },
  { id: 5, title: 'TheNautigal', type: 'TheNautigal-Regular', Icon: () => <></> },
  { id: 6, title: 'TwinkleStar', type: 'TwinkleStar-Regular', Icon: () => <></> },
  { id: 7, title: 'VujahdayScript', type: 'VujahdayScript-Regular', Icon: () => <></> },
  { id: 8, title: 'Waterfall', type: 'Waterfall-Regular', Icon: () => <></> },
  { id: 9, title: 'Yesteryear', type: 'Yesteryear-Regular', Icon: () => <></> }
];
const fontSizes = [
  { id: 1, title: 20, type: 20, Icon: () => <></> },
  { id: 2, title: 24, type: 24, Icon: () => <></> },
  { id: 3, title: 28, type: 28, Icon: () => <></> },
  { id: 4, title: 32, type: 32, Icon: () => <></> },
  { id: 5, title: 36, type: 36, Icon: () => <></> },
  { id: 6, title: 40, type: 40, Icon: () => <></> },
  { id: 7, title: 48, type: 48, Icon: () => <></> }
  // { id: 8, title: 56, type: 56, Icon: () => <></> },
  // { id: 9, title: 64, type: 64, Icon: () => <></> },
  // { id: 10, title: 72, type: 72, Icon: () => <></> },
  // { id: 11, title: 80, type: 80, Icon: () => <></> },
  // { id: 12, title: 88, type: 88, Icon: () => <></> },
  // { id: 13, title: 96, type: 96, Icon: () => <></> },
  // { id: 14, title: 100, type: 100, Icon: () => <></> }
];
const EsignFreeTextSign = ({ canvasRdxAction = selectReplaceCanvasAction, userNodess }) => {
  const [selectedFontFamily, setSelectedFontFamily] = useState(fontFamilies[0]);
  const [selectedFontSize, setSelectedFontSize] = useState(fontSizes[6]);
  const [userText, setUserText] = useState('');
  const canvas = useSelector(canvasRdxAction);
  const activeObject = useSelector((state) => state.esignMain.selectedActiveObject);
  const usersNodesRdx = useSelector(selectUsersNodes);
  const usersNodes = userNodess ? userNodess : usersNodesRdx;
  const { userDetails } = useAccount();
  const dispatch = useDispatch();
  const [previewImageWithContainer, setPreviewImageWithContainer] = useState(null);
  const [imgPreviewTemp, setImgPreviewTemp] = useState('');
  const handleSelectFontFamily = (value) => {
    console.log(value, 'debug101');
    setSelectedFontFamily(() => {
      handleCreateImage(userText, value, selectedFontSize);
      return value;
    });
  };
  const handleSelectFontSize = (value) => {
    console.log(value, 'debug102');
    setSelectedFontSize(() => {
      handleCreateImage(userText, selectedFontFamily, value);
      return value;
    });
  };

  const handleAddSign = () => {
    if (canvas !== null) {
      // debugger;
      // const textbox = replaceStylizeSignature({
      //   canvas,
      //   activeObject,
      //   usersNodes,
      //   text: userText,
      //   textBoxProps: {
      //     fontFamily: selectedFontFamily?.type,
      //     fontSize: selectedFontSize?.type
      //   }
      // });

      replacePlaceholderwithImage(previewImageWithContainer, canvas, (img, enhancedImageResolution) => {
        dispatch(setPlaceHolderValue({ value: { id: img?.id, addedValue: enhancedImageResolution, actionType: PlaceHolderTypes.sign } }));
      });
      // dispatch(
      //   setPlaceHolderValue({
      //     value: { id: activeObject?.id, addedValue: textbox.toDataURL({ multiplier: 10 }), actionType: PlaceHolderTypes.sign }
      //   })
      // );
      dispatch(setEsignSelectedSignType(''));
    }
  };
  const handleCancel = () => {
    setSelectedFontFamily({});
    setSelectedFontSize({});
    setUserText('');
  };

  const handleCreateImage = (text, fontFamily, fontSize) => {
    const textbox = replaceStylizeSignature({
      canvas,
      activeObject,
      usersNodes,
      text: text,
      textBoxProps: {
        fontFamily: fontFamily?.type,
        fontSize: fontSize?.type
      },
      isAddOnCanvas: false
    });
    const img = textbox.toDataURL({ multiplier: 10 });
    setImgPreviewTemp(img);
  };
  const placeholderWidth = canvas?.getActiveObject()?.width;
  const placeholderHeight = canvas?.getActiveObject()?.height;

  return (
    <>
      <div className="w-full flex flex-col gap-[12px] mt-[12px] esignAppFreeTextSign">
        <input
          type="text"
          placeholder="Full Name"
          className="border-[1px] border-[#D1D5DB] py-[8px] px-[16px] rounded-[6px]"
          value={userText}
          onChange={(e) => {
            if (e?.target?.value) {
              handleCreateImage(e?.target?.value, selectedFontFamily, selectedFontSize);
            }

            setUserText(e?.target?.value);
          }}
        />
        <CommonDropdown items={fontFamilies} buttonLabel={selectedFontFamily?.title || 'Typeface'} onSelect={handleSelectFontFamily} />
        {/* <CommonDropdown items={fontSizes} buttonLabel={selectedFontSize?.title || 'Font Size'} onSelect={handleSelectFontSize} /> */}
        <div className="w-full h-[140px] border-[3px] border-dashed rounded-[10px] flex flex-col items-center justify-center gap-[12px] cursor-pointer overflow-auto">
          {/* <p
            className="freeTextSignTextPreview"
            style={{
              fontSize: `${selectedFontSize?.type}px`,
              fontFamily: `${selectedFontFamily?.type}`
            }}
          >
            {userText}
          </p> */}

          <CustomSignTemplate
            width={placeholderWidth}
            height={placeholderHeight}
            imageUrl={imgPreviewTemp}
            cb={(imageurl) => {
              setPreviewImageWithContainer(imageurl);
            }}
          />
        </div>
        <div className="w-full">
          <EsignValueRemoveSignBtn
            onRemoveOnClick={handleCancel}
            onSignOnClick={handleAddSign}
            isSignBtnDisabled={!userText || !selectedFontFamily?.type || !selectedFontSize?.type}
            isRemoveBtnDisabled={!userText && !selectedFontFamily?.type && !selectedFontSize?.type}
          />
        </div>
      </div>
    </>
  );
};

export default EsignFreeTextSign;
