import React from 'react';

const CompetitiveEdgeIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7531_12762)">
        <path
          d="M3.66797 19.5V9.75C3.66797 9.55109 3.74699 9.36032 3.88764 9.21967C4.02829 9.07902 4.21906 9 4.41797 9H8.91797"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.91797 19.5V5.25C8.91797 5.05109 8.99699 4.86032 9.13764 4.71967C9.27829 4.57902 9.46906 4.5 9.66797 4.5H15.668C15.8669 4.5 16.0576 4.57902 16.1983 4.71967C16.339 4.86032 16.418 5.05109 16.418 5.25V19.5"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.668 19.5V13.5C21.668 13.3011 21.589 13.1103 21.4483 12.9697C21.3076 12.829 21.1169 12.75 20.918 12.75H16.418"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M11.918 9.375L13.043 9V12.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M2.16797 19.5H23.168" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_7531_12762">
          <rect width="24" height="24" fill="white" transform="translate(0.667969)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CompetitiveEdgeIcon;
