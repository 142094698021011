import PublicFooter from 'app/components/public/home/PublicFooter';
import PublicHeader from 'app/components/public/home/PublicHeader';
import useLoadTailwindCss from 'hooks/useLoadTailwindCss';

const withPublicCommonHeader = (WrappedComponent) => {
  return (props) => {
    useLoadTailwindCss();
    return (
      <div className="flex flex-col justify-between h-screen">
        <div className=" h-fit">
          <PublicHeader />
        </div>

        <section className="w-full sm:w-[60%] mx-auto h-[100%]">
          <WrappedComponent {...props} />
        </section>

        <PublicFooter />
      </div>
    );
  };
};
export default withPublicCommonHeader;
