import LogoIcon from 'app/assets/icons/LogoIcon';
import React from 'react';

const EsignAppLogo = () => {
  return (
    <>
      <div
        className="flex items-center gap-[10px] cursor-pointer"
        onClick={() => {
          window.location.href = '/';
        }}
      >
        {/* <div className="w-[24px] h-[24px] bg-[#D9D9D9] rounded-full"></div> */}
        {/* <h4 className="font-[500] text-[22px] text-[#3B82F6]">E-Sign</h4> */}
        <LogoIcon width={150} />
      </div>
    </>
  );
};

export default EsignAppLogo;
