import React from 'react';

const IamTheOnlySignerIcon = () => {
  return (
    <svg width="151" height="140" viewBox="0 0 151 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7372_14461)">
        <path
          d="M69.376 76.9772C69.3492 76.9772 69.3323 76.9765 69.3264 76.9762L66.1192 76.9765V76.5005H69.338C69.4126 76.5032 70.9331 76.5464 72.334 74.8354C74.4218 72.2853 76.1363 65.9725 72.4479 50.3799C67.4884 29.4135 71.6248 10.8908 73.6334 3.90037C73.7324 3.55453 73.7501 3.19026 73.6851 2.83634C73.6202 2.48242 73.4744 2.14856 73.2592 1.86113C73.044 1.5737 72.7653 1.34057 72.4452 1.18017C72.1251 1.01978 71.7724 0.936503 71.4148 0.936931H23.5161C23.152 0.937422 22.7931 1.02433 22.4685 1.19061C22.144 1.35688 21.863 1.59784 21.6483 1.89391C20.7974 3.07204 19.6297 5.33533 18.899 9.42774L18.4336 9.34361C19.1801 5.16229 20.3858 2.83242 21.2658 1.61408C21.5244 1.25733 21.8629 0.966979 22.2539 0.766619C22.645 0.566259 23.0774 0.46153 23.5161 0.460939H71.4148C71.8457 0.460521 72.2708 0.560965 72.6565 0.754334C73.0422 0.947702 73.3779 1.2287 73.6371 1.57512C73.8964 1.92154 74.0721 2.3239 74.1503 2.75041C74.2285 3.17691 74.2071 3.61588 74.0877 4.03262C72.0895 10.9868 67.9744 29.4133 72.908 50.2697C76.6482 66.0816 74.8501 72.5312 72.6832 75.1574C71.2539 76.8895 69.679 76.9772 69.376 76.9772Z"
          fill="#E6E6E6"
        />
        <path
          d="M60.7471 85.6647C60.7203 85.6647 60.7034 85.664 60.6976 85.6637L57.4903 85.664V85.188H60.7092C60.7841 85.1903 62.3042 85.2339 63.7052 83.5229C65.7929 80.9728 67.5074 74.66 63.819 59.0674C58.8595 38.101 62.9959 19.5783 65.0045 12.5879C65.1035 12.242 65.1212 11.8778 65.0563 11.5238C64.9913 11.1699 64.8455 10.8361 64.6303 10.5486C64.4151 10.2612 64.1364 10.0281 63.8164 9.86767C63.4963 9.70728 63.1435 9.624 62.7859 9.62443H14.8873C14.5231 9.62492 14.1642 9.71183 13.8396 9.87811C13.5151 10.0444 13.2341 10.2853 13.0194 10.5814C12.1685 11.7595 11.0008 14.0228 10.2701 18.1152L9.80469 18.0311C10.5512 13.8498 11.7569 11.5199 12.6369 10.3016C12.8955 9.94483 13.2341 9.65448 13.6251 9.45412C14.0161 9.25376 14.4485 9.14903 14.8873 9.14844H62.7859C63.2168 9.14802 63.6419 9.24847 64.0276 9.44183C64.4133 9.6352 64.749 9.9162 65.0083 10.2626C65.2675 10.609 65.4432 11.0114 65.5214 11.4379C65.5996 11.8644 65.5782 12.3034 65.4589 12.7201C63.4606 19.6743 59.3456 38.1008 64.2791 58.9572C68.0193 74.7691 66.2213 81.2187 64.0543 83.8449C62.625 85.577 61.0502 85.6647 60.7471 85.6647Z"
          fill="#CCCCCC"
        />
        <path
          d="M52.1153 94.3522C52.0886 94.3522 52.0717 94.3515 52.0658 94.3512L4.9054 94.3515C4.45135 94.352 4.0041 94.2406 3.60276 94.0268C3.20143 93.8131 2.85826 93.5036 2.60328 93.1254C2.3483 92.7473 2.18928 92.312 2.14014 91.8577C2.091 91.4033 2.15324 90.9438 2.32141 90.5193C4.03255 86.2162 6.33651 75.8923 2.54167 55.0961C-1.93937 30.5397 2.07235 21.6649 4.00513 18.9891C4.26375 18.6323 4.60231 18.342 4.99332 18.1416C5.38433 17.9413 5.81678 17.8365 6.25554 17.8359H54.1542C54.5851 17.8355 55.0102 17.936 55.3959 18.1293C55.7816 18.3227 56.1173 18.6037 56.3765 18.9501C56.6358 19.2965 56.8114 19.6989 56.8896 20.1254C56.9678 20.5519 56.9464 20.9909 56.8271 21.4076C54.8289 28.3618 50.7138 46.7883 55.6474 67.6447C59.3876 83.4566 57.5896 89.9062 55.4226 92.5324C53.9933 94.2645 52.4184 94.3522 52.1153 94.3522ZM6.25554 18.3119C5.89137 18.3124 5.53245 18.3993 5.2079 18.5656C4.88335 18.7319 4.60232 18.9728 4.38762 19.2689C2.49069 21.8951 -1.44119 30.635 3.00676 55.0101C6.63197 74.8765 4.87376 85.3817 2.76042 90.6962C2.61798 91.0468 2.56452 91.4276 2.60486 91.8042C2.64519 92.1808 2.77804 92.5414 2.99144 92.8535C3.20303 93.1684 3.48818 93.4263 3.82189 93.6045C4.15559 93.7827 4.52764 93.8758 4.9054 93.8755H52.0774C52.1519 93.8791 53.6725 93.9215 55.0734 92.2104C57.1612 89.6603 58.8757 83.3475 55.1873 67.7549C50.2277 46.7885 54.3642 28.2658 56.3728 21.2754C56.4718 20.9295 56.4895 20.5653 56.4245 20.2113C56.3596 19.8574 56.2137 19.5236 55.9985 19.2361C55.7834 18.9487 55.5047 18.7156 55.1846 18.5552C54.8645 18.3948 54.5118 18.3115 54.1542 18.3119H6.25554Z"
          fill="#3F3D56"
        />
        <path
          d="M46.0064 39.2532H8.41112C7.90944 39.2532 7.4283 39.0527 7.07356 38.6956C6.71882 38.3385 6.51953 37.8542 6.51953 37.3493C6.51953 36.8443 6.71882 36.36 7.07356 36.003C7.4283 35.6459 7.90944 35.4453 8.41112 35.4453H46.0064C46.5081 35.4453 46.9893 35.6459 47.344 36.003C47.6987 36.36 47.898 36.8443 47.898 37.3493C47.898 37.8542 47.6987 38.3385 47.344 38.6956C46.9893 39.0527 46.5081 39.2532 46.0064 39.2532Z"
          fill="#3B82F6"
        />
        <path
          d="M46.0064 47.347H8.41112C7.90944 47.347 7.4283 47.1464 7.07356 46.7893C6.71882 46.4323 6.51953 45.948 6.51953 45.443C6.51953 44.9381 6.71882 44.4538 7.07356 44.0967C7.4283 43.7397 7.90944 43.5391 8.41112 43.5391H46.0064C46.5081 43.5391 46.9893 43.7397 47.344 44.0967C47.6987 44.4538 47.898 44.9381 47.898 45.443C47.898 45.948 47.6987 46.4323 47.344 46.7893C46.9893 47.1464 46.5081 47.347 46.0064 47.347Z"
          fill="#CCCCCC"
        />
        <path
          d="M46.0064 55.4407H8.41112C7.90944 55.4407 7.4283 55.2402 7.07356 54.8831C6.71882 54.526 6.51953 54.0417 6.51953 53.5368C6.51953 53.0318 6.71882 52.5475 7.07356 52.1905C7.4283 51.8334 7.90944 51.6328 8.41112 51.6328H46.0064C46.5081 51.6328 46.9893 51.8334 47.344 52.1905C47.6987 52.5475 47.898 53.0318 47.898 53.5368C47.898 54.0417 47.6987 54.526 47.344 54.8831C46.9893 55.2402 46.5081 55.4407 46.0064 55.4407Z"
          fill="#CCCCCC"
        />
        <path
          d="M48.8463 63.5267H11.251C10.7493 63.5267 10.2681 63.3261 9.91341 62.969C9.55867 62.612 9.35938 62.1277 9.35938 61.6227C9.35938 61.1178 9.55867 60.6335 9.91341 60.2764C10.2681 59.9193 10.7493 59.7188 11.251 59.7188H48.8463C49.348 59.7188 49.8291 59.9193 50.1838 60.2764C50.5386 60.6335 50.7379 61.1178 50.7379 61.6227C50.7379 62.1277 50.5386 62.612 50.1838 62.969C49.8291 63.3261 49.348 63.5267 48.8463 63.5267Z"
          fill="#CCCCCC"
        />
        <path
          d="M48.8463 71.6204H11.251C10.7493 71.6204 10.2681 71.4198 9.91341 71.0628C9.55867 70.7057 9.35938 70.2214 9.35938 69.7165C9.35938 69.2115 9.55867 68.7272 9.91341 68.3702C10.2681 68.0131 10.7493 67.8125 11.251 67.8125H48.8463C49.348 67.8125 49.8291 68.0131 50.1838 68.3702C50.5386 68.7272 50.7379 69.2115 50.7379 69.7165C50.7379 70.2214 50.5386 70.7057 50.1838 71.0628C49.8291 71.4198 49.348 71.6204 48.8463 71.6204Z"
          fill="#CCCCCC"
        />
        <path
          d="M26.1448 31.1595H8.41112C7.90944 31.1595 7.4283 30.9589 7.07356 30.6018C6.71882 30.2448 6.51953 29.7605 6.51953 29.2555C6.51953 28.7506 6.71882 28.2663 7.07356 27.9092C7.4283 27.5522 7.90944 27.3516 8.41112 27.3516H26.1448C26.6464 27.3516 27.1276 27.5522 27.4823 27.9092C27.8371 28.2663 28.0364 28.7506 28.0364 29.2555C28.0364 29.7605 27.8371 30.2448 27.4823 30.6018C27.1276 30.9589 26.6464 31.1595 26.1448 31.1595Z"
          fill="#3B82F6"
        />
        <path
          d="M23.855 84.6703C24.2367 83.0436 26.0299 82.5104 27.496 82.57C28.4222 82.633 29.341 82.7793 30.2413 83.0073C31.1834 83.2194 32.1162 83.4706 33.0444 83.737C34.7465 84.2256 36.431 84.8165 38.1611 85.1985C39.6014 85.5165 41.1666 85.703 42.603 85.264C43.9837 84.842 45.1551 83.806 45.5307 82.3736C45.8848 81.0238 45.3987 79.5479 44.0993 78.9311C42.9142 78.3686 41.4173 78.6546 40.83 79.9268C40.1678 81.3611 40.8294 82.9253 41.878 83.9633C42.474 84.5265 43.1269 85.0256 43.8259 85.4524C44.5353 85.9391 45.325 86.2951 46.1581 86.5038C47.526 86.7979 49.1554 86.3646 49.8608 85.0492C50.0176 84.7515 50.1119 84.4244 50.1379 84.0884C50.1796 83.6311 49.47 83.6339 49.4285 84.0884C49.309 85.3982 47.7851 86.0116 46.6455 85.868C45.8762 85.7397 45.1416 85.4527 44.4878 85.0251C43.8568 84.6547 43.2588 84.2301 42.7006 83.7558C41.7341 82.9139 40.828 81.5284 41.4772 80.2146C42.0058 79.1448 43.3837 79.1555 44.2218 79.8454C45.2368 80.6809 45.0558 82.1974 44.3864 83.1897C43.6452 84.2883 42.3017 84.7489 41.035 84.7986C39.6586 84.8525 38.2937 84.5346 36.9783 84.1586C35.3438 83.6914 33.7256 83.1686 32.0833 82.7288C31.1509 82.4791 30.2114 82.2537 29.2622 82.0784C28.3647 81.8793 27.4428 81.8151 26.5266 81.8878C25.0464 82.0598 23.5355 82.9271 23.171 84.4805C23.0662 84.9273 23.75 85.118 23.855 84.6703Z"
          fill="#3F3D56"
        />
        <path
          d="M96.3253 125.034C96.3661 129.72 96.7071 134.399 97.3463 139.041C97.3686 139.204 97.393 139.367 97.4154 139.53L141.017 139.285C141.145 139.123 141.271 138.959 141.393 138.794C141.8 138.245 142.182 137.681 142.532 137.097C144.215 134.284 144.986 130.992 144.214 128.72L144.2 128.684C144.033 128.187 143.771 127.727 143.427 127.332C141.666 125.337 138.106 125.67 134.875 127.28C137.773 124.088 140.08 120.059 140.256 116.463C140.428 112.955 138.605 110.449 136.629 108.32C136.564 108.249 136.499 108.181 136.435 108.113C136.403 108.077 136.37 108.044 136.338 108.009C134.796 106.386 132.999 104.786 130.397 104.98C127.543 105.193 124.379 107.599 122.16 110.402C119.942 113.204 118.467 116.387 116.973 119.493C115.477 122.597 113.858 125.781 111.457 128.434C114.709 124.294 117.331 119.583 118.3 115.002C119.268 110.42 118.416 106.02 115.518 103.863C114.638 103.228 113.62 102.814 112.548 102.657C112.423 102.636 112.296 102.619 112.167 102.604C109.484 102.297 106.281 103.231 103.555 105.463C100.554 107.921 98.4729 111.565 97.4375 115.025C96.4022 118.484 96.2919 121.815 96.3253 125.034Z"
          fill="#C5D2E8"
        />
        <path
          d="M124.855 139.383L125.689 139.378C125.954 139.212 126.22 139.047 126.487 138.885C126.653 138.779 126.82 138.678 126.987 138.575C129.753 136.875 132.536 135.244 135.336 133.683C138.133 132.12 140.945 130.63 143.771 129.211C143.846 129.178 143.907 129.12 143.944 129.046C143.98 128.973 143.99 128.889 143.972 128.809C143.969 128.798 143.964 128.788 143.958 128.779C143.918 128.705 143.83 128.674 143.702 128.737C143.355 128.912 143.007 129.087 142.66 129.266C139.827 130.709 137.005 132.224 134.196 133.811C131.389 135.397 128.598 137.054 125.823 138.78C125.764 138.816 125.706 138.854 125.648 138.889C125.383 139.054 125.12 139.219 124.855 139.383Z"
          fill="white"
        />
        <path
          d="M114.105 139.443L114.621 139.44C114.737 139.276 114.852 139.112 114.968 138.949C117.7 135.092 120.431 131.236 123.162 127.38C127.634 121.067 132.105 114.754 136.575 108.44C136.601 108.406 136.621 108.368 136.633 108.326C136.671 108.186 136.569 108.104 136.438 108.119C136.383 108.127 136.33 108.146 136.283 108.177C136.236 108.207 136.196 108.246 136.166 108.293C133.658 111.834 131.152 115.373 128.646 118.911C124.197 125.192 119.749 131.473 115.301 137.753C115.018 138.152 114.735 138.553 114.452 138.951C114.336 139.115 114.22 139.279 114.105 139.443Z"
          fill="white"
        />
        <path
          d="M102.618 136.425C102.644 137.3 102.702 138.16 102.777 139.013C102.792 139.176 102.806 139.339 102.821 139.502L103.362 139.499C103.345 139.336 103.329 139.173 103.314 139.01C103.2 137.778 103.119 136.532 103.108 135.253C103.093 131.705 103.437 128.165 104.133 124.688C104.873 120.943 105.935 117.271 107.308 113.712C108.749 109.958 110.486 106.326 112.502 102.852C112.54 102.795 112.557 102.727 112.55 102.659C112.528 102.504 112.323 102.458 112.169 102.606C112.139 102.635 112.113 102.668 112.092 102.705C111.842 103.137 111.597 103.57 111.357 104.004C109.414 107.498 107.752 111.142 106.385 114.902C105.083 118.473 104.092 122.151 103.425 125.895C102.799 129.368 102.529 132.896 102.618 136.425Z"
          fill="white"
        />
        <path
          d="M49.9199 84.8372C49.8302 84.762 49.7646 84.6618 49.7314 84.5492C49.6983 84.4366 49.6989 84.3166 49.7334 84.2044C49.7678 84.0921 49.8345 83.9927 49.925 83.9185C50.0155 83.8444 50.1258 83.7989 50.242 83.7877C51.5316 83.6623 52.8331 83.7966 54.0707 84.1827L66.677 88.1015C66.8402 88.1522 66.9919 88.2348 67.1233 88.3445C67.2548 88.4542 67.3635 88.5889 67.4433 88.7409C67.523 88.8929 67.5722 89.0592 67.5881 89.2304C67.604 89.4015 67.5863 89.5742 67.5359 89.7384C67.4855 89.9026 67.4034 90.0553 67.2944 90.1876C67.1854 90.3199 67.0516 90.4294 66.9006 90.5096C66.7495 90.5899 66.5843 90.6395 66.4143 90.6554C66.2442 90.6714 66.0727 90.6536 65.9096 90.6028L53.3032 86.6841C52.0649 86.3005 50.9147 85.6727 49.9199 84.8372Z"
          fill="#3F3D56"
        />
        <path
          d="M101.593 101.114C101.251 100.97 100.944 100.751 100.694 100.474C100.445 100.198 100.259 99.8691 100.149 99.512C100.039 99.1549 100.008 98.778 100.059 98.4077C100.11 98.0374 100.24 97.6828 100.442 97.3689L98.2812 85.9947L103.788 85.5625L104.121 96.6881C104.622 97.0638 104.97 97.6117 105.097 98.2279C105.224 98.844 105.122 99.4857 104.81 100.031C104.499 100.577 103.999 100.988 103.406 101.188C102.813 101.388 102.168 101.361 101.593 101.114Z"
          fill="#FFB8B8"
        />
        <path
          d="M97.9436 95.2476L92.3344 68.9532C92.104 67.8732 92.3093 66.7454 92.9052 65.8177C93.501 64.8901 94.4385 64.2387 95.5114 64.0069C96.5844 63.775 97.7049 63.9817 98.6265 64.5814C99.5481 65.1811 100.195 66.1247 100.426 67.2047L106.035 93.4988C106.094 93.7765 106.041 94.0664 105.888 94.3049C105.735 94.5433 105.494 94.7109 105.218 94.7709L99.2073 96.0699C99.0707 96.0994 98.9296 96.1016 98.7922 96.0763C98.6547 96.051 98.5236 95.9986 98.4062 95.9223C98.2888 95.8459 98.1876 95.747 98.1082 95.6312C98.0288 95.5155 97.9729 95.3851 97.9436 95.2476Z"
          fill="#3B82F6"
        />
        <path d="M88.7349 136.614H85.8362L84.457 125.359L88.7356 125.36L88.7349 136.614Z" fill="#FFB8B8" />
        <path
          d="M83.7661 135.773H89.3566V139.316H80.2461C80.2461 138.851 80.3371 138.39 80.514 137.961C80.6909 137.531 80.9502 137.14 81.2771 136.811C81.6039 136.482 81.992 136.221 82.419 136.043C82.8461 135.865 83.3038 135.773 83.7661 135.773Z"
          fill="#2F2E41"
        />
        <path d="M98.4302 136.372H95.5316L94.1523 125.117L98.4309 125.117L98.4302 136.372Z" fill="#FFB8B8" />
        <path
          d="M93.4614 135.539H99.0519V139.082H89.9414C89.9414 138.617 90.0325 138.156 90.2094 137.726C90.3862 137.296 90.6455 136.906 90.9724 136.577C91.2992 136.248 91.6873 135.987 92.1143 135.809C92.5414 135.631 92.9991 135.539 93.4614 135.539Z"
          fill="#2F2E41"
        />
        <path
          d="M85.2643 134.512C85.1557 134.422 85.0659 134.311 85.0003 134.186C84.9347 134.061 84.8945 133.924 84.8822 133.783L81.5122 96.47C81.4991 96.3252 81.5155 96.1791 81.5603 96.0409C81.6051 95.9026 81.6775 95.775 81.7729 95.6658C81.8683 95.5566 81.9848 95.4681 82.1153 95.4057C82.2458 95.3433 82.3876 95.3083 82.532 95.3028L99.4333 94.6648C99.5795 94.6593 99.7253 94.6843 99.8616 94.7381C99.9978 94.7919 100.122 94.8734 100.225 94.9775C100.329 95.0817 100.41 95.2062 100.463 95.3433C100.517 95.4804 100.542 95.6271 100.536 95.7744L99.1854 132.944C99.1755 133.204 99.0719 133.452 98.894 133.641C98.7161 133.83 98.476 133.947 98.2184 133.971L94.8106 134.283C94.6692 134.296 94.5268 134.28 94.3916 134.237C94.2564 134.194 94.1311 134.124 94.0232 134.031C93.9153 133.938 93.827 133.825 93.7633 133.697C93.6997 133.569 93.662 133.43 93.6526 133.288L92.0886 109.674C92.0875 109.629 92.0774 109.584 92.059 109.543C92.0405 109.501 92.0141 109.464 91.9812 109.433C91.9482 109.402 91.9095 109.378 91.8672 109.362C91.825 109.347 91.78 109.339 91.7349 109.341H91.7335C91.6883 109.34 91.6432 109.347 91.6008 109.363C91.5585 109.379 91.5197 109.403 91.4869 109.435C91.454 109.466 91.4277 109.503 91.4094 109.545C91.3912 109.587 91.3814 109.632 91.3806 109.677L89.981 133.45C89.967 133.704 89.8629 133.945 89.6874 134.129C89.512 134.313 89.2769 134.427 89.0248 134.452L86.0477 134.752C85.9078 134.766 85.7665 134.752 85.632 134.711C85.4975 134.67 85.3725 134.602 85.2643 134.512Z"
          fill="#2F2E41"
        />
        <path
          d="M91.7645 58.4174C94.9718 58.4174 97.5719 55.8003 97.5719 52.572C97.5719 49.3436 94.9718 46.7266 91.7645 46.7266C88.5571 46.7266 85.957 49.3436 85.957 52.572C85.957 55.8003 88.5571 58.4174 91.7645 58.4174Z"
          fill="#FFB8B8"
        />
        <path
          d="M80.5788 96.7186C80.4754 96.6004 80.3995 96.4605 80.3564 96.3091C80.3133 96.1576 80.3043 95.9985 80.3299 95.8431C81.4021 89.2835 80.7978 79.6417 80.102 72.7082C79.9204 70.869 80.2235 69.014 80.9807 67.3302C81.7378 65.6463 82.9224 64.193 84.4151 63.1165L87.448 60.5C87.6496 60.3281 87.9068 60.2365 88.1709 60.2425L95.7056 60.4591C95.9773 60.4666 96.2358 60.5788 96.4276 60.7726L97.5919 63.3678L97.6039 63.3766C98.9913 64.4031 100.104 65.7597 100.844 67.3251C101.583 68.8904 101.925 70.6157 101.84 72.3468L100.731 95.4173C100.717 95.6855 100.603 95.9388 100.413 96.1272C100.223 96.3157 99.9697 96.4258 99.703 96.436L81.4143 97.0828C81.4025 97.083 81.3908 97.0833 81.379 97.0833C81.2275 97.0835 81.0777 97.051 80.9397 96.9881C80.8017 96.9252 80.6786 96.8333 80.5788 96.7186Z"
          fill="#3B82F6"
        />
        <path
          d="M58.6453 87.0969C58.6362 86.7232 58.7086 86.3521 58.8574 86.0097C59.0062 85.6672 59.2278 85.3617 59.5065 85.1148C59.7853 84.8679 60.1145 84.6855 60.4708 84.5804C60.8272 84.4754 61.2021 84.4503 61.5692 84.5069L70.9953 77.8672L73.641 82.748L63.692 87.6068C63.5567 88.2212 63.2022 88.7643 62.6956 89.1331C62.1891 89.502 61.5657 89.671 60.9436 89.6081C60.3215 89.5453 59.744 89.2549 59.3204 88.7921C58.8968 88.3292 58.6566 87.7261 58.6453 87.0969Z"
          fill="#FFB8B8"
        />
        <path
          d="M66.575 88.2482C66.4367 88.2273 66.304 88.1789 66.1845 88.106C66.065 88.0331 65.961 87.937 65.8785 87.8234L62.2409 82.835C62.0743 82.6059 62.0047 82.3196 62.0475 82.0389C62.0902 81.7582 62.2418 81.5061 62.469 81.3378L84.0082 65.4246C84.893 64.771 85.9994 64.4979 87.0841 64.6654C87.6213 64.7483 88.1369 64.9369 88.6016 65.2204C89.0663 65.5039 89.4709 65.8768 89.7925 66.3177C90.114 66.7587 90.3461 67.259 90.4755 67.7902C90.6049 68.3215 90.6291 68.8731 90.5467 69.4138C90.4643 69.9544 90.277 70.4734 89.9953 70.9411C89.7136 71.4089 89.3432 71.8162 88.9051 72.1398L67.3659 88.053C67.1842 88.1879 66.9644 88.2608 66.7386 88.2608C66.6838 88.2608 66.6291 88.2566 66.575 88.2482Z"
          fill="#3B82F6"
        />
        <path
          d="M92.0629 57.7785C92.833 56.828 92.7487 55.3802 92.1306 54.3232C91.5125 53.2662 90.4632 52.5383 89.3835 51.9697C88.3038 51.4011 87.1568 50.954 86.1408 50.2769C85.1247 49.5999 85.169 46.7338 84.8984 45.5379L85.5437 45.7542C85.2957 45.4349 85.2162 44.4912 84.9682 44.1719C85.6973 44.2444 86.258 44.9412 86.9871 45.0137L86.5663 43.8769C89.0666 43.7218 90.6211 45.4706 93.1214 45.3154C94.3602 45.2386 95.7056 45.1899 96.724 45.904C97.609 46.5246 98.0569 47.5986 98.3509 48.6431C98.982 50.8858 99.0801 53.398 97.99 55.4545C96.8999 57.5111 94.3873 58.8969 92.1922 58.1543L92.0629 57.7785Z"
          fill="#2F2E41"
        />
        <path
          d="M60.1779 139.453H150.265C150.327 139.453 150.388 139.427 150.432 139.383C150.476 139.338 150.501 139.278 150.501 139.215C150.501 139.151 150.476 139.091 150.432 139.046C150.388 139.002 150.327 138.977 150.265 138.977H60.1779C60.1151 138.977 60.055 139.002 60.0107 139.046C59.9663 139.091 59.9414 139.151 59.9414 139.215C59.9414 139.278 59.9663 139.338 60.0107 139.383C60.055 139.427 60.1151 139.453 60.1779 139.453Z"
          fill="#3F3D56"
        />
      </g>
      <defs>
        <clipPath id="clip0_7372_14461">
          <rect width="150" height="139.073" fill="white" transform="translate(0.5 0.460938)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IamTheOnlySignerIcon;
