import DateInput from 'layout/MainLayoutNewUI/Form/Input/DateInput';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { PlaceHolderTypes, replaceRectWithText } from 'utils/esign.helper';
import dayjs from 'dayjs';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { selectUsersNodes } from 'store/esignMainSlice';
import EsignValueRemoveSignBtn from 'app/components/Buttons/EsignValueRemoveSignBtn';
import { setPlaceHolderValue } from 'store/mainSlice';
import CustomCalender from 'app/components/Calender/CustomCalender';
const EsignAddDate = ({ canvasRdxAction = selectReplaceCanvasAction, usersNodess }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()).format('MM/DD/YYYY'));
  const activeObject = useSelector((state) => state.esignMain.selectedActiveObject);
  const canvas = useSelector(canvasRdxAction);
  const usersNodesRdx = useSelector(selectUsersNodes);
  const usersNodes = usersNodess ? usersNodess : usersNodesRdx;
  const dispatch = useDispatch();
  const handleCancel = () => {
    setSelectedDate('');
  };
  const handleConfirm = () => {
    const textbox = replaceRectWithText({ canvas, activeObject, usersNodes, text: selectedDate });
    console.log(textbox.toDataURL(), 'textboxdataUrl');
    dispatch(
      setPlaceHolderValue({ value: { id: activeObject?.id, addedValue: textbox.toDataURL(), actionType: PlaceHolderTypes.dateSigned } })
    );
  };
  return (
    <>
      <DateInput
        value={selectedDate}
        onChange={(_, e) => {
          console.log(e, 'DateInput');
          setSelectedDate(dayjs(e).format('MM/DD/YYYY'));
        }}
      />

      <div className="flex justify-between items-center gap-[10px] mt-3">
        {/* <TextButton
          title={'Cancel'}
          style={{
            color: '#FF3A3A'
          }}
          onClick={handleCancel}
        />
        <SecondaryButton
          title="Confirm"
          style={{
            backgroundColor: '#E9F0FC'
          }}
          onClick={handleConfirm}
        /> */}
        <EsignValueRemoveSignBtn
          onRemoveOnClick={handleCancel}
          onSignOnClick={handleConfirm}
          //   isSignBtnDisabled={!initialsInputs}
          //   isRemoveBtnDisabled={!initialsInputs}
          primaryBtnLabel={`Add Date`}
          isShowConsent={false}
        />
      </div>
    </>
  );
};

export default EsignAddDate;
