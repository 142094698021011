import React from 'react';
import EsignCanvasAddText from './EsignCanvasAddText';

const EsignAddMobileNumber = ({ ...props }) => {
  return (
    <>
      <EsignCanvasAddText label={'Mobile'} {...props} />
    </>
  );
};

export default EsignAddMobileNumber;
