import { useAccount } from 'context/AccountProvider';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMyUserDetails } from 'services/UserService';
import { setEsignMainAppLoader } from 'store/esignMainSlice';

const useCheckIsAuthenticated = () => {
  const dispatch = useDispatch();
  const { setUserDetails } = useAccount();
  const getMyUserDetailsHandler = async () => {
    try {
      dispatch(setEsignMainAppLoader(true));
      const result = await getMyUserDetails();
      sessionStorage.setItem('userType', result.user_type);
      sessionStorage.setItem('userId', result?.organisation?.id);
      console.log(result, 'resDataNew');
      setUserDetails(result);
      if (result.user_type !== 'internal') {
        navigate('/dashboard/home');
      } else {
        navigate('/dashboard/home');
      }
    } catch (error) {
      console.log(error, 'resDataNew');
    } finally {
      dispatch(setEsignMainAppLoader(false));
    }
  };

  return { getMyUserDetailsHandler };
};

export default useCheckIsAuthenticated;
