import React, { useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import ArrowDownIcon from 'app/assets/icons/ArrowDownIcon';
import StatusColumnDropdownButton from './StatusColumnDropdownButton';
import { downloadEsignDoc } from 'services/eSignService';
import AuditTrialDrawer from 'app/components/Drawer/AuditTrialDrawer';
import { DeclineDocumentPopup } from 'app/components/Popups/DeclineDocumentPopup';
import { useNavigate } from 'react-router';
import { useAccount } from 'context/AccountProvider';
import { MarkAsInvalidDocumentPopup } from 'app/components/Popups/MarkAsInvalidDocumentPopup';
import { EditValidityPopup } from 'app/components/Popups/AdditionalSettingModal/EditValidityPopup';
const StatusColumnDropdown = ({ buttonTitle, onButtonClick, item, isDownloadAsPrimary, isDeclineMenu, isShowAuditTrail, refetch }) => {
  const {
    _id: { $oid: documentId },
    email: sender_email
  } = item;
  const [showAuditTrialDrawer, setShowAuditTrialDrawer] = useState(false);
  const [declinePopupShow, setDeclinePopupShow] = useState(false);
  const [markAsInvalidPopupShow, setMarkAsInvalidPopupShow] = useState(false);
  const [editValidityPopupShow, setEditValidityPopupShow] = useState(false);
  const navigate = useNavigate();
  const { userDetails } = useAccount();
  const handleDeclined = () => {
    setDeclinePopupShow(true);
  };

  const handleDownload = async (e) => {
    e.stopPropagation();
    try {
      // Fetch the document data
      const response = await downloadEsignDoc(documentId);

      // If response.data is a Blob, use it directly
      const blob = new Blob([response], { type: 'application/pdf' });

      // Create a link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `KR_${documentId}.pdf`; // Specify the filename for the download
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left w-[140px] max-w-[140px]">
      {isDownloadAsPrimary ? (
        <StatusColumnDropdownButton onButtonClick={handleDownload} title={'Download'} />
      ) : (
        <StatusColumnDropdownButton
          onButtonClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onButtonClick?.(e);
          }}
          title={buttonTitle}
        />
      )}
      {showAuditTrialDrawer && <AuditTrialDrawer onClose={() => setShowAuditTrialDrawer(false)} documentId={documentId} />}
      {declinePopupShow && (
        <DeclineDocumentPopup
          open={declinePopupShow}
          onClose={() => {
            setDeclinePopupShow(false);
            refetch?.();
          }}
          documentId={documentId}
        />
      )}
      {markAsInvalidPopupShow && (
        <MarkAsInvalidDocumentPopup
          open={markAsInvalidPopupShow}
          onClose={() => {
            setMarkAsInvalidPopupShow(false);
            refetch?.();
          }}
          documentId={documentId}
        />
      )}
      {editValidityPopupShow && (
        <EditValidityPopup open={editValidityPopupShow} onClose={() => setEditValidityPopupShow(false)} currentItem={item} />
      )}
      <MenuItems
        transition
        className="z-20 absolute right-0 mt-2 w-[150px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in border-1 shadow-none commonDropdownItemContainerDefault"
        style={{
          zIndex: 999
        }}
      >
        <div>
          {sender_email !== userDetails?.email && isDeclineMenu ? (
            <MenuItem onClick={handleDeclined}>
              <div className="block px-[16px] py-2 text-sm text-gray-700 data-[focus]:bg-[#3B82F6] data-[focus]:text-[white] cursor-pointer commonDropdownItemDefault">
                Decline
              </div>
            </MenuItem>
          ) : null}

          {isShowAuditTrail ? (
            <MenuItem onClick={() => setShowAuditTrialDrawer(true)}>
              <div className="block px-[16px] py-2 text-sm text-gray-700 data-[focus]:bg-[#3B82F6] data-[focus]:text-[white] cursor-pointer commonDropdownItemDefault">
                Show Audit Trail
              </div>
            </MenuItem>
          ) : (
            false
          )}

          {!isDownloadAsPrimary && !buttonTitle ? (
            <MenuItem onClick={handleDownload}>
              <div className="block px-[16px] py-2 text-sm text-gray-700 data-[focus]:bg-[#3B82F6] data-[focus]:text-[white] cursor-pointer commonDropdownItemDefault">
                Download
              </div>
            </MenuItem>
          ) : null}
          {isDownloadAsPrimary ? (
            <MenuItem
              onClick={() => {
                navigate(`/dashboard/add-digi-esign/${documentId}`);
              }}
            >
              <div className="block px-[16px] py-2 text-sm text-gray-700 data-[focus]:bg-[#3B82F6] data-[focus]:text-[white] cursor-pointer commonDropdownItemDefault">
                Preview
              </div>
            </MenuItem>
          ) : null}
          {sender_email === userDetails?.email && !isDownloadAsPrimary ? (
            <>
              <MenuItem
                onClick={() => {
                  setMarkAsInvalidPopupShow(true);
                }}
              >
                <div className="block px-[16px] py-2 text-sm text-gray-700 data-[focus]:bg-[#3B82F6] data-[focus]:text-[white] cursor-pointer commonDropdownItemDefault">
                  Mark as Invalid
                </div>
              </MenuItem>
              <MenuItem onClick={() => setEditValidityPopupShow(true)}>
                <div className="block px-[16px] py-2 text-sm text-gray-700 data-[focus]:bg-[#3B82F6] data-[focus]:text-[white] cursor-pointer commonDropdownItemDefault">
                  Edit Validity
                </div>
              </MenuItem>
            </>
          ) : null}
        </div>
      </MenuItems>
    </Menu>
  );
};

export default StatusColumnDropdown;
