import React, { useEffect, useRef, useState } from 'react';
import CustomCalender from './CustomCalender';
import CustomSelectTime from './CustomSelectTime';
import { getExpirtyModuleDateFormat } from 'app/helper/selectTime.const';

const SetDurationDateAndTime = ({ expiryData, handleExpiryData, item, editButtons }) => {
  const [showDateCalender, setShowDateCalender] = useState(false);
  const [showTimeCalender, setShowTimeCalender] = useState(false);
  const { date, hours, minutes, amPm } = item;
  const dateValue = new Date(date);
  const timeString = hours || minutes || amPm ? `${hours || '00'}:${minutes || '00'} ${amPm || 'AM'}` : '-Time-';
  console.log('dateValue', item, hours, minutes, amPm, dateValue);
  const calenderRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calenderRef.current && !calenderRef.current.contains(event.target)) {
        setShowDateCalender(false);
        setShowTimeCalender(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="flex gap-[4px] items-center h-[36px] w-[230px]" ref={calenderRef}>
      <button
        className="bg-[white] border-[1px] border-[#D4D4D8] rounded-[6px] h-[34px] px-[16px] flex flex-col items-center justify-center w-[50%] text-[13px]"
        onClick={() => {
          setShowDateCalender(!showDateCalender);
          setShowTimeCalender(false);
        }}
      >
        {date ? date : '-Date-'}

        <div className="absolute w-full">
          {showDateCalender && (
            <div className="absolute top-[20px] left-[20px]">
              <CustomCalender
                handleUpdateFilter={(_, value) => {
                  const formattedDate = getExpirtyModuleDateFormat(value);
                  console.log(value, formattedDate, new Date().getMonth(), 'valueDataopnoi');
                  handleExpiryData(item, 'date', formattedDate);
                }}
                // value={date}
                minDate={new Date()}
              />
            </div>
          )}
        </div>
      </button>
      <button
        className="bg-[white] border-[1px] border-[#D4D4D8] rounded-[6px] h-[34px] px-[16px] flex items-center justify-center w-[50%] disabled:opacity-50 cursor-not-allowed"
        onClick={() => {
          setShowDateCalender(false);
          setShowTimeCalender(!showTimeCalender);
        }}
        disabled={!date}
      >
        {timeString}
        <div className="absolute">
          {showTimeCalender && (
            <div className="absolute top-[20px] right-[-40px]">
              <CustomSelectTime
                hours={hours}
                minutes={minutes}
                amPm={amPm}
                handleExpiryData={handleExpiryData}
                currentItem={item}
                date={date}
                expiryData={expiryData}
              />
            </div>
          )}
        </div>
      </button>
      {editButtons}

      {/* <CustomCalender /> */}
    </div>
  );
};

export default SetDurationDateAndTime;
