import UploadIconOutline from 'app/assets/icons/UploadIconOutline';
import React from 'react';

const EsignUploadButton = ({ title, onClick }) => {
  return (
    <div
      className="flex w-[196px] h-[36px] bg-[white] border-1 border-[#D1D5DB] rounded-[6px] flex items-center justify-between mt-[34px] cursor-pointer esignUploadBtn"
      onClick={onClick}
    >
      <div className="flex items-center justify-center w-[100%] text-center">
        <h4 className="text-[14px] font-[500] text-[#101010] w-[90%]">{title}</h4>
      </div>
      <div className="border-l-[1px] border-[#D1D5DB] h-full px-[6px] flex items-center justify-center">
        {' '}
        <UploadIconOutline />
      </div>
    </div>
  );
};

export default EsignUploadButton;
