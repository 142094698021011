import DateIcon from 'app/assets/icons/DateIcon';
import DesignationIcon from 'app/assets/icons/DesignationIcon';
import DragIcon from 'app/assets/icons/DragIcon';
import EmaiIcon from 'app/assets/icons/EmaiIcon';
import MobileIcon from 'app/assets/icons/MobileIcon';
import NameIcon from 'app/assets/icons/NameIcon';
import SignatureIcon from 'app/assets/icons/SignatureIcon';
import withTour from 'app/components/Demo_JoyRide/withJoyrideTour';
import CommonDropdown from 'app/components/Dropdowns/CommonDropdown';
import useCheckIsIamTheOnlySignerDocCreate from 'app/hooks/useCheckIsIamTheOnlySignerDocCreate';
import usePlaceholderDropOnCanvas from 'app/hooks/usePlaceholderDropOnCanvas';
import InitialsIcon from 'assets/images/eSign/InitialsIcon';
import classNames from 'classnames';
import { stringify } from 'flatted';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setAlreadyUsedPlaceholder } from 'store/esignMainSlice';
import { setSelectedRecipient } from 'store/mainSlice';
import { selectCanvasAction } from 'store/placeHolderCanvasReducer';
import { PlaceHolderTypes, RecipientDropdownActionsConst } from 'utils/esign.helper';

const actionsList = [
  { id: 1, title: 'Approve' },
  { id: 2, title: 'Sign' }
];

const placeholderFields = [
  { title: 'Sign', Icon: SignatureIcon, height: 55, width: 168, type: PlaceHolderTypes.initials, type: PlaceHolderTypes.sign },
  { title: 'Initials', Icon: InitialsIcon, height: 55, width: 168, type: PlaceHolderTypes.initials, type: PlaceHolderTypes.initials },
  { title: 'Name', Icon: NameIcon, height: 55, width: 168, type: PlaceHolderTypes.initials, type: PlaceHolderTypes.name },
  { title: 'Mobile Number', Icon: MobileIcon, height: 55, width: 168, type: PlaceHolderTypes.initials, type: PlaceHolderTypes.mobile },
  {
    title: 'Designation',
    Icon: DesignationIcon,
    height: 55,
    width: 168,
    type: PlaceHolderTypes.initials,
    type: PlaceHolderTypes.designation
  },
  { title: 'Date', Icon: DateIcon, height: 55, width: 168, type: PlaceHolderTypes.initials, type: PlaceHolderTypes.dateSigned },
  { title: 'Email', Icon: EmaiIcon, height: 55, width: 168, type: PlaceHolderTypes.initials, type: PlaceHolderTypes.email }
];

const PlaceholderItems = ({ Icon, title, userColor, isActiveBorder, ...props }) => {
  return (
    <div
      className={classNames('placeholderItem flex flex px-[12px] gap-[12px] items-center h-[56px]  mt-[10px] shadow-md', {
        placeholderItemActive: isActiveBorder
      })}
      style={{
        borderLeftColor: userColor
      }}
      {...props}
    >
      <DragIcon />
      <Icon />
      <h4>{title}</h4>
    </div>
  );
};
const RecipientDropdownList = ({ startTour }) => {
  const recipientList = useSelector((state) => state.main.recipientList);
  const selectedRecipient = useSelector((state) => state.main.selectedRecipient);
  const dispatch = useDispatch();
  const signConfigsNodes = useSelector((state) => state.main.signConfigsNodes);
  const alreadyUsedPlaceholder = useSelector((state) => state.esignMain.alreadyUsedPlaceholder);
  const [placeholdersList, setPlaceholdersList] = useState([]);
  const isIamTheOnlySigner = useCheckIsIamTheOnlySignerDocCreate();
  // const [alreadyUsedPlaceholder, setAlreadyUsedPlaceholder] = useState([]);
  console.log(recipientList, 'recipientList');
  const handleSelect = (item) => {
    dispatch(setSelectedRecipient(item));
    console.log('Selected item:', item);
    previousUsedPlaceholder();
  };

  const handleSavingTourInLocal = () => {
    const tourData = {};
    localStorage.setItem('tour');
  };

  const previousUsedPlaceholder = () => {
    const allUniqueTypes = [
      ...new Set(
        Object.values(signConfigsNodes)
          .flat()
          .map((item) => item.type)
      )
    ];
    dispatch(setAlreadyUsedPlaceholder(allUniqueTypes));
    console.log(allUniqueTypes, 'recipientDropdownList');
  };
  const recipientDropdownList = useMemo(() => {
    return recipientList
      ?.filter((filItem) => filItem?.actionType === RecipientDropdownActionsConst.NeedsToSign)
      .map((item) => {
        return {
          ...item,
          title: item.name
        };
      });
  }, [recipientList]);
  //**************** Canvas Placeholder dragging section *********************** */

  const onDragStarting = (e, dragObject) => {
    let currentTargetRect = e.currentTarget.getBoundingClientRect();

    const offset = [e.clientX - currentTargetRect.left, e.clientY - currentTargetRect.top];
    const jsonString = stringify({ selected: dragObject });

    // Set the custom data to be transferred during the drag
    e.dataTransfer.setData('customData', jsonString);
  };

  const onDragEnding = (e) => {
    e.stopPropagation();
  };
  //****************  *********************** */

  useEffect(() => {
    console.log(selectedRecipient, 'selectedRecipient');
    if (selectedRecipient?.id) {
      const updatedFields = placeholderFields.map((item) => {
        return {
          ...item,
          name: item?.title,
          color: selectedRecipient?.color
        };
      });
      setPlaceholdersList(updatedFields);
    }
  }, [selectedRecipient]);

  useEffect(() => {
    if (selectedRecipient === null) {
      dispatch(setSelectedRecipient(recipientDropdownList[0]));
    }
  }, [recipientDropdownList]);
  useEffect(() => {
    const tourSeen = localStorage.getItem('placeholderTourSeen');

    if (!tourSeen) {
      setTimeout(() => {
        startTour();
      }, 1000);
    }
  }, []);

  console.log(alreadyUsedPlaceholder, 'isActiveBorder');
  return (
    <>
      <div className="w-full h-[100%] px-[10px] border-l-1 border-[#D1D9E0]  overflow-auto placefieldRightSideBarContainer">
        {isIamTheOnlySigner ? (
          <></>
        ) : (
          <CommonDropdown
            items={recipientDropdownList}
            buttonLabel={selectedRecipient?.name || 'Select Recipient'}
            onSelect={handleSelect}
            className="placefieldRightSideBar" // Optional additional class for the dropdown container
            itemClassName="dropdownList" // Optional additional class for each dropdown item
          />
        )}

        <div className="placeholderItemsContainer h-auto w-full ">
          {placeholdersList.map((item) => {
            const isActiveBorder = alreadyUsedPlaceholder.includes(item?.type);

            return (
              <PlaceholderItems
                title={item.title}
                Icon={item.Icon}
                userColor={item?.color}
                draggable={true}
                onDragStart={(e) => onDragStarting(e, item)}
                onDragEnd={onDragEnding}
                isActiveBorder={isActiveBorder}
                isHightlighted={true}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
const steps = [
  {
    target: '.placefieldRightSideBar',
    content: 'Click on the downward arrow to select between different recipients.',
    placement: 'left-end',
    disableBeacon: true
  },
  {
    target: '.placeholderItemsContainer',
    content: 'Drag the required fields on the document to set up a signing placeholder.',
    placement: 'left-end'
  }
  // {
  //   target: '.my-other-step',
  //   content: 'This another awesome feature!'
  // }
];
export default withTour(steps)(RecipientDropdownList);
