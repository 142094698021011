import CrossIcon from 'app/assets/icons/CrossIcon';
import DeclineIcon from 'app/assets/icons/DeclineIcon';
import { Button, Checkbox, Label, Modal, Textarea, TextInput } from 'flowbite-react';
import { useState } from 'react';
import EsignAppPrimaryButton from '../Buttons/EsignAppPrimaryButton';
import EsignAppSecondaryButton from '../Buttons/EsignAppSecondaryButton';
import { updateDocumentStatus } from 'services/eSignService';
import { useDispatch } from 'react-redux';
import { setEsignMainAppLoader } from 'store/esignMainSlice';
import { useAccount } from 'context/AccountProvider';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { RecipientDropdownActionsConst, createDocumentType } from 'utils/esign.helper';
import MarkAsInvalidIcon from 'app/assets/icons/MarkAsInvalidIcon';
import IamTheOnlySignerIcon from 'app/assets/icons/IamtheOnlySigner/IamTheOnlySignerIcon';
import MulitpleSignatories from 'app/assets/icons/IamtheOnlySigner/MulitpleSignatories';

export function StartESignWorkflowModal({ open, onClose, isFromCanvas }) {
  const dispatch = useDispatch();
  const { userDetails } = useAccount();
  const naviagte = useNavigate();
  const [reasonText, setReasonText] = useState('');
  const navigate = useNavigate();

  return (
    <>
      <Modal show={open} size="md" onClose={onClose} popup theme={theme}>
        {/* <Modal.Header /> */}
        <Modal.Body>
          <div className="p-[20px]">
            <div className="w-full flex items-start justify-between">
              <div className="w-full">
                <h3 className="text-[16px] font-[700] text-[#101010]">Continue Workflow with</h3>
                <div className="w-full h-fit grid grid-cols-2 gap-[10px] mt-[20px] ">
                  <div
                    className="w-full hover:bg-[#F0F9FF] rounded-[6px] h-[200px] border-[1px] border-[#D4D4D8] flex flex-col items-center justify-center gap-[20px]cursor-pointer hover:border-[2px] hover:border-[#007AFF]  py-[10px]"
                    onClick={() => {
                      navigate(
                        `/dashboard/create-document?${createDocumentType.createDocumentType}=${createDocumentType.IamTheOnlySigner}`
                      );
                    }}
                  >
                    <IamTheOnlySignerIcon />
                    <h4 className="font-[500] text-[16px] text-[#101010] mt-[20px]">I am the Only Signer</h4>
                  </div>
                  <div
                    className="w-full hover:bg-[#F0F9FF] rounded-[6px] h-[200px] border-[1px] border-[#D4D4D8]  flex flex-col items-center justify-center gap-[20px] cursor-pointer  hover:border-[2px] hover:border-[#007AFF]  py-[10px]"
                    onClick={() => {
                      navigate(
                        `/dashboard/create-document?${createDocumentType.createDocumentType}=${createDocumentType.MultiPartySigner}`
                      );
                    }}
                  >
                    <MulitpleSignatories />
                    <h4 className="font-[500] text-[16px] text-[#101010] mt-[20px]">Multiple Signatories</h4>
                  </div>
                </div>
              </div>

              <button onClick={onClose}>
                <CrossIcon />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const theme = {
  root: {
    base: 'fixed inset-x-0 top-0 z-50 h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full',
    show: {
      on: 'flex bg-[black] bg-opacity-50',
      off: 'hidden'
    },
    sizes: {
      sm: 'max-w-sm',
      md: 'max-w-md',
      lg: 'max-w-lg',
      xl: 'max-w-xl',
      '2xl': 'max-w-2xl',
      '3xl': 'max-w-3xl',
      '4xl': 'max-w-4xl',
      '5xl': 'max-w-5xl',
      '6xl': 'max-w-6xl',
      '7xl': 'max-w-7xl'
    },
    positions: {
      'top-left': 'items-start justify-start',
      'top-center': 'items-start justify-center',
      'top-right': 'items-start justify-end',
      'center-left': 'items-center justify-start',
      center: 'items-center justify-center',
      'center-right': 'items-center justify-end',
      'bottom-right': 'items-end justify-end',
      'bottom-center': 'items-end justify-center',
      'bottom-left': 'items-end justify-start'
    }
  },
  content: {
    base: 'relative h-full w-full p-4 md:h-auto',
    inner: 'relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700 w-[520px]'
  },
  body: {
    base: 'flex-1 overflow-auto',
    popup: 'pt-0'
  },
  header: {
    base: 'flex items-start justify-between rounded-t border-b p-5 dark:border-gray-600',
    popup: 'border-b-0 p-2',
    title: 'text-xl font-medium text-gray-900 dark:text-white',
    close: {
      base: 'ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white',
      icon: 'h-5 w-5'
    }
  },
  footer: {
    base: 'flex items-center space-x-2 rounded-b border-gray-200 p-6 dark:border-gray-600',
    popup: 'border-t'
  }
};
