import React from 'react';
import EsignCanvasAddText from './EsignCanvasAddText';

const EsignAddDesignation = ({ ...props }) => {
  return (
    <>
      <EsignCanvasAddText label={'Designation'} {...props} />
    </>
  );
};

export default EsignAddDesignation;
