import UploadIconOutline from 'app/assets/icons/UploadIconOutline';
import MenuItemTriangle from 'app/assets/icons/UserAvatarMenusIcons/MenuItemTriangle';
import EsignAvatar from 'app/components/Avatar/EsignAvatar';
import EsignUploadButton from 'app/components/Buttons/EsignUploadButton';
import { CreateYourSignatureModal } from 'app/components/Popups/CreateYourSignatureModal';
import { useAccount } from 'context/AccountProvider';
import { Button, Popover } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { getUserSignature } from 'services/eSignService';
import { setEsignMainAppLoader } from 'store/esignMainSlice';
import { UserAvatarMenusConst } from 'utils/constants';
import { clearAllLocalStorage, getFormattedInitials } from 'utils/esign.helper';
const AvatarComp = ({ initials }) => {
  return (
    <>
      <div className="px-[5px] py-[6px] text-[#086036] bg-[#CEFFE7] text-[16px] font-[600] rounded-full flex items-center justify-center">
        {initials}
      </div>
    </>
  );
};
export function UserHeaderAvatarMenu() {
  const navigate = useNavigate();
  const { userDetails, logout } = useAccount();
  const userFullName = userDetails?.first_name + ' ' + userDetails?.last_name || '';
  const userEmail = userDetails?.email || '';
  const initials = getFormattedInitials(userDetails?.first_name || '', userDetails?.last_name || '');
  const [userSignature, setUserSignature] = useState(null);
  const dispatch = useDispatch();
  const [uploadSignatureModal, setUploadSignatureModal] = useState(false);
  console.log(userDetails, 'userDetails');

  const getUserSign = async () => {
    dispatch(setEsignMainAppLoader(true));
    try {
      const data = await getUserSignature();
      if (data?.sign_document) {
        setUserSignature(`data:image/png;base64,${data?.sign_document}`);
      }
    } catch (error) {
    } finally {
      dispatch(setEsignMainAppLoader(false));
    }
  };
  useEffect(() => {
    getUserSign();
  }, []);

  const logoutHandler = () => {
    logout();
    window.location.href = '/';
    clearAllLocalStorage();
  };
  console.log(userSignature, userDetails, 'userSignature');
  return (
    <>
      {uploadSignatureModal && (
        <CreateYourSignatureModal
          afterUploadCb={async () => {
            setUploadSignatureModal(false);
            await getUserSign();
          }}
          showModal={uploadSignatureModal}
          onClose={() => {
            setUploadSignatureModal(false);
          }}
        />
      )}
      <Popover
        aria-labelledby="profile-popover"
        content={
          <div className="w-[16vw] h-full p-3">
            <div className="w-full h-full flex items-center gap-[10px]">
              <div className="px-[5px] py-[6px] text-[#086036] bg-[#CEFFE7] text-[16px] font-[600] rounded-full flex items-center justify-center">
                {initials}
              </div>
              <div>
                <h3 className="text-[#030712] font-[700] text-[16px] mb-[5px] capitalize">{userFullName}</h3>
                <h4 className="text-[#6B7280] font-[400] text-[13px]">{userEmail}</h4>
              </div>
            </div>
            <div className="w-full h-full">
              {userSignature ? (
                <>
                  <label className="font-[600] mt-[10px] mb-[5px]">My Signature</label>
                  <div
                    className="w-full  object-contain border border-[black] rounded-[10px] p-[10px] h-fit "
                    onClick={() => setUploadSignatureModal(true)}
                  >
                    <img src={userSignature} className="w-full h-[20px]" />
                  </div>
                </>
              ) : (
                <div
                  className="h-[36px] flex bg-[white] border-1 border-[#D1D5DB] rounded-[6px] items-center justify-between mt-[20px] cursor-pointer esignUploadBtn"
                  onClick={() => setUploadSignatureModal(true)}
                >
                  <div className="flex items-center justify-center w-[100%] text-center">
                    <h4 className="text-[14px] font-[500] text-[#101010] w-[90%]">{'Upload Your Signature'}</h4>
                  </div>
                  <div className="border-l-[1px] border-[#D1D5DB] h-full px-[6px] flex items-center justify-center">
                    {' '}
                    <UploadIconOutline />
                  </div>
                </div>
              )}

              <div className="mt-[20px]">
                {UserAvatarMenusConst.map((item) => {
                  return (
                    <div
                      className="w-full flex items-center justify-between hover:bg-[#80808015] cursor-pointer py-[4px] mb-[10px]"
                      onClick={() => navigate(item.link)}
                    >
                      <div className="w-full flex items-center gap-[8px]    " key={item.title}>
                        <item.Icon />
                        <h4 className="text-[#030712] text-[12px] font-[400]">{item.title}</h4>
                      </div>
                      <MenuItemTriangle />
                    </div>
                  );
                })}
              </div>

              <button
                className="text-[#101010] font-[500] text-[14px] h-[40px] border-1 border-[#D1D5DB] w-full rounded-[6px] mt-[20px]"
                onClick={logoutHandler}
              >
                Sign Out
              </button>
            </div>
          </div>
        }
      >
        <div className="px-[5px] py-[6px] text-[#086036] bg-[#CEFFE7] text-[16px] font-[600] rounded-full flex items-center justify-center cursor-pointer">
          {initials}
        </div>
      </Popover>
    </>
  );
}
