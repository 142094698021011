import AccountSettingIcon from 'app/assets/icons/UserAvatarMenusIcons/AccountSettingIcon';
import BillingSettingIcon from 'app/assets/icons/UserAvatarMenusIcons/BillingSettingIcon';
import NotificationSettingIcon from 'app/assets/icons/UserAvatarMenusIcons/NotificationSettingIcon';
import SecuritySettingIcon from 'app/assets/icons/UserAvatarMenusIcons/SecuritySettingIcon';

export const UserAvatarMenusConst = [
  {
    title: 'Go To Dashboard',
    Icon: AccountSettingIcon,
    link: '/dashboard/home'
  },
  { title: 'Account Settings', Icon: AccountSettingIcon, link: '/dashboard/account-setting' },
  { title: 'Notification Settings', Icon: NotificationSettingIcon, link: '/dashboard/notification-setting' },
  { title: 'Security', Icon: SecuritySettingIcon, link: '' },
  { title: 'Billing', Icon: BillingSettingIcon, link: '' }
];
