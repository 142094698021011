import React, { useEffect, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'context/AccountProvider';
import { getDocumentById, getDocUsingLink, getSignConfig } from 'services/eSignService';
import {
  selectCombinedSignAndUnSignedNode,
  setCombinedSignAndUnSignedNodeRdx,
  setEsignMainAppLoader,
  setEsignUploadedDocumentsImages,
  setUploadedDocumentAllData,
  setUsersNodes
} from 'store/esignMainSlice';
import { setPublicRecipientDetails } from 'store/mainSlice';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { addSignImage, addSignRectBox, combineSignatures, PlaceHolderTypes } from 'utils/esign.helper';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { isDateTimeExpiredSinceNow } from 'app/helper/selectTime.const';

const useRecipientAddSignForUnAuthUser = () => {
  const { documentId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const combinedSignAndUnSignedNode = useSelector(selectCombinedSignAndUnSignedNode);
  const currentUserData = useSelector((state) => state.main.publicRecipientDetails);
  const canvas = useSelector(selectReplaceCanvasAction);
  const { userDetails } = useAccount();
  const navigate = useNavigate();
  const checkIsDocumentExpired = (resData) => {
    const userEmail = resData.email;
    const currentUserDataConfig = resData?.recipient_configs?.filter?.((item) => {
      return item?.email === userEmail;
    });
    if (currentUserDataConfig?.length > 0) {
      const item = currentUserDataConfig?.[0];
      const isExpired = isDateTimeExpiredSinceNow(item?.expiry_date_time);
      if (item?.status) {
        alert('You have already peformed your all actions on this document');
        navigate('/dashboard/documents/inbox');
      } else if (isExpired) {
        alert('Document already expired. please contact your admin');
        navigate('/dashboard/documents/inbox');
      }
    }
    console.log(currentUserDataConfig, 'resData');
  };
  //**********For Unauth user *********** */
  const getDocumentByIdHandler = useCallback(
    async (id) => {
      try {
        dispatch(setEsignMainAppLoader(true));
        const res = await getDocUsingLink(id);
        const resData = res?.data?.data;

        if (!resData) return;
        checkIsDocumentExpired(resData);
        dispatch(setUploadedDocumentAllData(resData));
        const documentImages = resData.document_images?.map((img) => ({
          url: `data:image/png;base64,${img}`
        }));
        dispatch(setEsignUploadedDocumentsImages(documentImages));

        const userEmail = resData.email;
        const userSignConfigs = resData.sign_configs?.[userEmail];
        dispatch(setUsersNodes(userSignConfigs));

        const combinedSigns = combineSignatures({ [userEmail]: userSignConfigs || [] }, resData.signs);
        dispatch(setPublicRecipientDetails(resData));
        dispatch(setCombinedSignAndUnSignedNodeRdx(combinedSigns));
      } catch (error) {
        console.log(error, 'errorData');
        toast.error(error.message || 'Something went wrong!');
        console.error('Error fetching document:', error.message);
      } finally {
        dispatch(setEsignMainAppLoader(false));
      }
    },
    [dispatch]
  );

  //**************************************** */

  const handleAddPlaceholders = useCallback(
    (item) => {
      addSignRectBox(canvas, item, userDetails?.email);
    },
    [canvas, userDetails?.email]
  );
  //**********For Unauth user *********** */
  useEffect(() => {
    if (documentId) {
      getDocumentByIdHandler(documentId);
    }
  }, [documentId, getDocumentByIdHandler]);
  //**************************************** */

  useEffect(() => {
    if (canvas && Array.isArray(combinedSignAndUnSignedNode)) {
      combinedSignAndUnSignedNode.forEach((item) => {
        if (item?.objectType === 'signs') {
          if (item?.email === currentUserData?.email) {
            addSignImage(item, canvas);
          }
        } else if (item?.objectType === 'sign_config' && !item?.is_aadhaar_true) {
          handleAddPlaceholders(item);
        }
      });
      canvas.renderAll();
    }
  }, [canvas, combinedSignAndUnSignedNode, handleAddPlaceholders]);
  console.log(currentUserData, 'currentUserData');
  return null;
};

export default useRecipientAddSignForUnAuthUser;
