import React from 'react';

const CoreFeaturesIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7531_12717)">
        <path
          d="M15.7052 12.1555L19.0596 14.174C19.1455 14.2269 19.245 14.2533 19.3458 14.2499C19.4466 14.2464 19.5442 14.2133 19.6262 14.1546C19.7082 14.0959 19.7711 14.0143 19.8069 13.92C19.8427 13.8257 19.8498 13.7229 19.8274 13.6246L18.9152 9.85679L21.9002 7.33679C21.977 7.27214 22.0327 7.18608 22.0602 7.08962C22.0878 6.99315 22.0859 6.89067 22.0549 6.79526C22.0239 6.69985 21.9652 6.61585 21.8862 6.55399C21.8072 6.49214 21.7116 6.45525 21.6115 6.44804L17.6937 6.13772L16.1843 2.5621C16.1444 2.46943 16.0782 2.39049 15.9939 2.33502C15.9095 2.27956 15.8108 2.25 15.7099 2.25C15.609 2.25 15.5103 2.27956 15.426 2.33502C15.3417 2.39049 15.2755 2.46943 15.2356 2.5621L13.7262 6.13772L9.80837 6.44804C9.70792 6.45441 9.61168 6.49069 9.53202 6.55222C9.45236 6.61374 9.39292 6.69769 9.36137 6.79327C9.32981 6.88884 9.32757 6.99168 9.35494 7.08854C9.38231 7.1854 9.43803 7.27186 9.51493 7.33679L12.4999 9.85679L11.5802 13.6246C11.5578 13.7229 11.565 13.8257 11.6008 13.92C11.6366 14.0143 11.6994 14.0959 11.7815 14.1546C11.8635 14.2133 11.961 14.2464 12.0618 14.2499C12.1626 14.2533 12.2622 14.2269 12.3481 14.174L15.7052 12.1555Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M8.06172 11.0234L2.58203 16.5031" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.07516 16.7578L4.08203 21.7509" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M15.9208 16.6641L10.832 21.7528" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_7531_12717">
          <rect width="24" height="24" fill="white" transform="translate(0.332031)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CoreFeaturesIcon;
