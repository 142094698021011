import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from './store/index.js';
// style + assets
import 'assets/scss/style.scss';
import 'assets/scss/customstyle.scss';
import 'assets/scss/main.scss';

import axios from 'axios';
import config from 'config.js';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { SessionExpired } from 'utils/toastMessages.js';
import { clearAllLocalStorage } from 'utils/esign.helper.js';

axios.interceptors.request.use(
  (reqConfig) => {
    const reqConfiglocal = reqConfig;
    const token = localStorage.getItem('token');
    if (token) {
      reqConfiglocal.headers.Authorization = `Bearer ${token}`;
    }

    return reqConfiglocal;
  },
  (error) => {
    console.log(error, 'global_error');
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    console.log(response, 'global_response');
    return response;
  },
  (error) => {
    console.log(error.response, 'error_main');
    if (error?.response?.status === 401) {
      // toast.error(SessionExpired);
      setTimeout(() => {
        // clearAllLocalStorage();
        // window.location.href = '/';
      }, 2000);
    }
    return Promise.reject(error);
  }
);
// document.addEventListener('keypress', logKey);

// let logger = function () {
//     /* +-------------------------------------------------------------------+
//      desc: Enable or disable logger and disable warn function.
//      +-------------------------------------------------------------------+ */
//     let oldConsoleLog = null;
//     let pub = {};

//     pub.enableLogger = function enableLogger() {
//         if (oldConsoleLog == null) return;
//         window['console']['log'] = oldConsoleLog;
//         console.log('=== LOG ENABLED ===');
//     };
//     pub.disableLogger = function disableLogger() {
//         oldConsoleLog = console.log;
//         window['console']['log'] = () => {
//         };
//     };
//     pub.disableWarn = function disableWarn() {
//         window['console']['warn'] = () => { };
//     };
//     return pub;
// }();

// function logKey(e) {
//     /* +-------------------------------------------------------------------+
//      desc: define log enable disable key.
//      +-------------------------------------------------------------------+ */
//     if (e.ctrlKey && e.shiftKey) {
//         switch (e.code) {
//             case 'KeyE':
//                 logger.disableLogger();
//                 break;
//             case 'KeyS':
//                 logger.enableLogger();
//                 break;
//             default:
//                 break;
//         }
//     }
// }

// logger.disableLogger();
// logger.disableWarn();

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
