import MainLayoutNewUI from 'layout/MainLayoutNewUI';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element }) => {
  const token = localStorage.getItem('token'); // or any other key you're using to store the token

  // If there's no token, redirect to the login page
  if (!token) {
    return <Navigate to="/login" />;
  }

  // Otherwise, render the private route component
  return element;
};

export default PrivateRoute;
