import ArrowToRightIcon from 'app/assets/icons/ArrowToRightIcon';
import PublicFooter from 'app/components/public/home/PublicFooter';
import PublicHeader from 'app/components/public/home/PublicHeader';
import classNames from 'classnames';
import useLoadTailwindCss from 'hooks/useLoadTailwindCss';
import { useLocation, useNavigate } from 'react-router';
import { AllSlugsWithTitle } from './FeaturesPage/features.constant';

const withBreadScumb = (WrappedComponent, { title }) => {
  return (props) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const allPaths = pathname.split('/');
    const breadcrumbs = allPaths.map((item) => {
      return {
        title: AllSlugsWithTitle[item],
        link: `/${item}`
      };
    });
    useLoadTailwindCss();
    return (
      <div className="w-full mt-[20px]">
        <div className="flex w-full items-center gap-[6px]">
          {breadcrumbs.map((item, index) => {
            const allLinks = item.link.split('/');
            const isCurrentLink = allLinks[allLinks.length - 1];
            console.log(isCurrentLink, 'isCurrentLink');
            return (
              <>
                <div
                  className={classNames('flex items-center cursor-pointer', { 'font-bold': index === breadcrumbs.length - 1 })}
                  onClick={() => {
                    navigate(item.link);
                  }}
                >
                  {item?.title}
                </div>
                {index !== breadcrumbs.length - 1 && <ArrowToRightIcon />}
              </>
            );
          })}
        </div>
        <div>
          <h1 className="font-[700] text-[56px] text-[#000000] mt-[20px]">{title}</h1>
          <WrappedComponent {...props} />
        </div>
      </div>
    );
  };
};
export default withBreadScumb;
