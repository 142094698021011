import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setEsignMainAppLoader } from 'store/esignMainSlice';

const useLoadTailwindCss = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Dynamically import SCSS file when component mounts
    import('../assets/scss/pages/newDashboard.scss')
      .then(() => {
        setIsMainScssLoaded(true);
        dispatch(setEsignMainAppLoader(true));
      })
      .catch((error) => {
        console.error('Error loading Dashboard SCSS:', error);
      })
      .finally(() => {
        dispatch(setEsignMainAppLoader(false));
      });
  }, []); // Run effect only once when component mounts
};
export default useLoadTailwindCss;
