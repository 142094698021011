import DocumentCompletedIcon from 'app/assets/icons/DocumentCompletedIcon';
import ApprovedText from 'app/components/Documents/DocumentList/DocumentStatusTexts/ApprovedText';
import DeclinedText from 'app/components/Documents/DocumentList/DocumentStatusTexts/DeclinedText';
import SignedText from 'app/components/Documents/DocumentList/DocumentStatusTexts/SignedText';
import { Avatar, Button, Timeline } from 'flowbite-react';
import { HiArrowNarrowRight, HiCalendar } from 'react-icons/hi';
import { downloadEsignDoc } from 'services/eSignService';
import { formatDateTime, getUserFullNameInitials, RecipientDropdownActionsConst } from 'utils/esign.helper';

export function AuditTrialTimeline({ data, documentId }) {
  console.log(data, 'datadebug90');

  const renderStatus = (item) => {
    if (item?.status === RecipientDropdownActionsConst.NeedsToSign) {
      return <SignedText />;
    } else if (item?.status === RecipientDropdownActionsConst.NeedsToApprove) {
      return <ApprovedText />;
    } else if (item?.status === RecipientDropdownActionsConst.Decline) {
      return <DeclinedText />;
    }
  };

  const handleAuditTrial = async (e) => {
    e.stopPropagation();
    try {
      // Fetch the document data
      const response = await downloadEsignDoc(documentId, 'audit_trail');

      // If response.data is a Blob, use it directly
      const blob = new Blob([response], { type: 'application/pdf' });

      // Create a link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `Audit_Trial_${documentId}.pdf`; // Specify the filename for the download
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };
  return (
    <>
      <Timeline theme={theme} className="w-full">
        {data.map((item) => {
          return (
            <Timeline.Item className="w-full justify-between" key={item?.email}>
              <div className="">
                <Timeline.Point
                  icon={() => (
                    <Avatar className="uppercase" theme={avatarTheme} placeholderInitials={getUserFullNameInitials(item?.name)} rounded />
                  )}
                />
                <Timeline.Content className="max-w-[146px]">
                  <Timeline.Time className="font-[500] text-[14px] text-[#101010]">{item?.name} </Timeline.Time>
                  <Timeline.Title className="font-[500] text-[14px] text-[#101010]">{`(${item?.email})`}</Timeline.Title>
                  {item?.description && (
                    <Timeline.Body className="font-[500] text-[14px] text-[#6B7280]">{item?.description}</Timeline.Body>
                  )}
                  {item?.status && (
                    <Timeline.Body className="font-[500] text-[14px] text-[#6B7280] mt-[4px]">
                      {renderStatus(item)} the document
                    </Timeline.Body>
                  )}
                </Timeline.Content>
              </div>

              <h4 className="font-[500] text-[14px] text-[#A0A0A0]">
                {formatDateTime(item?.date).withYear}
                <br />
                <span className="font-[500] text-[14px] text-[#A0A0A0]">{formatDateTime(item?.date).time}</span>
              </h4>
            </Timeline.Item>
          );
        })}
        <Timeline.Item className="w-full " theme={completedTimelineTheme}>
          <Timeline.Point icon={DocumentCompletedIcon} />
          <Timeline.Content className="max-w-[146px]">
            <Timeline.Time className="font-[500] text-[14px] text-[#101010]">Document Completed</Timeline.Time>
          </Timeline.Content>
        </Timeline.Item>
      </Timeline>
      <button
        className="text-[14px] font-[500] text-[#101010] border border-[#D1D5DB] rounded-[6px] h-[36px] bg-[white] w-full mt-[40px]"
        onClick={handleAuditTrial}
      >
        Download Audit Trail
      </button>
    </>
  );
}

const theme = {
  root: {
    direction: {
      horizontal: 'sm:flex',
      vertical: 'relative border-l-2 border-gray-200 dark:border-gray-700 pl-[30px]'
    }
  },
  item: {
    root: {
      horizontal: 'relative mb-6 sm:mb-0 ',
      vertical: 'flex gap-[30px] h-[100%]'
    },
    content: {
      root: {
        base: '',
        horizontal: 'mt-3 sm:pr-8',
        vertical: ''
      },
      body: {
        base: 'mb-4 text-base font-normal text-gray-500 dark:text-gray-400'
      },
      time: {
        base: 'mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500'
      },
      title: {
        base: 'text-lg font-semibold text-gray-900 dark:text-white'
      }
    },
    point: {
      horizontal: 'flex items-center',
      line: 'hidden h-0.5 w-full bg-gray-200 dark:bg-gray-700 sm:flex',
      marker: {
        base: {
          horizontal:
            'absolute -left-1.5 h-[40px] w-[40px] rounded-full border border-white bg-gray-200 dark:border-gray-900 dark:bg-gray-700',
          vertical:
            'absolute -left-1.5 mt-1.5 h-[40px] w-[40px] rounded-full border border-white bg-gray-200 dark:border-gray-900 dark:bg-gray-700'
        },
        icon: {
          base: 'h-3 w-3 text-cyan-600 dark:text-cyan-300',
          wrapper: 'absolute -left-[20px] flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[#CEFFE7]'
        }
      },
      vertical: ''
    }
  }
};

const completedTimelineTheme = {
  root: {
    direction: {
      horizontal: 'sm:flex',
      vertical: 'relative border-l-2 border-gray-200 dark:border-gray-700 w-full pl-[30px]'
    }
  },

  point: {
    horizontal: 'flex items-center',
    line: 'hidden h-0.5 w-full bg-gray-200 dark:bg-gray-700 sm:flex',
    marker: {
      base: {
        horizontal:
          'absolute -left-1.5 h-[40px] w-[40px] rounded-full border border-white bg-gray-200 dark:border-gray-900 dark:bg-gray-700',
        vertical:
          'absolute -left-1.5 mt-1.5 h-[40px] w-[40px] rounded-full border border-white bg-gray-200 dark:border-gray-900 dark:bg-gray-700'
      },
      icon: {
        base: 'h-3 w-3 text-cyan-600 dark:text-cyan-300',
        wrapper: 'absolute -left-[20px] flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[#2E844A]'
      }
    },
    vertical: ''
  }
};

const avatarTheme = {
  root: {
    base: 'flex items-center justify-center space-x-4 rounded',
    bordered: 'p-1 ring-2',
    rounded: 'rounded-full',
    color: {
      dark: 'ring-gray-800 dark:ring-gray-800',
      failure: 'ring-red-500 dark:ring-red-700',
      gray: 'ring-gray-500 dark:ring-gray-400',
      info: 'ring-cyan-400 dark:ring-cyan-800',
      light: 'ring-gray-300 dark:ring-gray-500',
      purple: 'ring-purple-500 dark:ring-purple-600',
      success: 'ring-green-500 dark:ring-green-500',
      warning: 'ring-yellow-300 dark:ring-yellow-500',
      pink: 'ring-pink-500 dark:ring-pink-500'
    },
    img: {
      base: 'rounded',
      off: 'relative overflow-hidden bg-gray-100 dark:bg-gray-600',
      on: '',
      placeholder: 'absolute -bottom-1 h-auto w-auto text-gray-400'
    },
    size: {
      xs: 'h-6 w-6',
      sm: 'h-8 w-8',
      md: 'h-10 w-10',
      lg: 'h-20 w-20',
      xl: 'h-36 w-36'
    },
    stacked: 'ring-2 ring-gray-300 dark:ring-gray-500',
    statusPosition: {
      'bottom-left': '-bottom-1 -left-1',
      'bottom-center': '-bottom-1',
      'bottom-right': '-bottom-1 -right-1',
      'top-left': '-left-1 -top-1',
      'top-center': '-top-1',
      'top-right': '-right-1 -top-1',
      'center-right': '-right-1',
      center: '',
      'center-left': '-left-1'
    },
    status: {
      away: 'bg-yellow-400',
      base: 'absolute h-3.5 w-3.5 rounded-full border-2 border-white dark:border-gray-800',
      busy: 'bg-red-400',
      offline: 'bg-gray-400',
      online: 'bg-green-400'
    },
    initials: {
      text: 'font-medium text-gray-600 dark:text-gray-300',
      base: 'relative inline-flex items-center justify-center overflow-hidden bg-[#CEFFE7]'
    }
  },
  group: {
    base: 'flex -space-x-4'
  },
  groupCounter: {
    base: 'relative flex h-10 w-10 items-center justify-center rounded-full bg-gray-700 text-xs font-medium text-white ring-2 ring-gray-300 hover:bg-gray-600 dark:ring-gray-500'
  }
};
