import React from 'react';

const WhoItsForIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7531_12709)">
        <path
          d="M5.10562 18.8944C4.24312 18.0319 4.815 16.2197 4.37625 15.1584C3.92063 14.0625 2.25 13.1719 2.25 12C2.25 10.8281 3.92063 9.9375 4.37625 8.84156C4.815 7.78125 4.24312 5.96812 5.10562 5.10562C5.96812 4.24312 7.78125 4.815 8.84156 4.37625C9.94219 3.92063 10.8281 2.25 12 2.25C13.1719 2.25 14.0625 3.92063 15.1584 4.37625C16.2197 4.815 18.0319 4.24312 18.8944 5.10562C19.7569 5.96812 19.185 7.78031 19.6238 8.84156C20.0794 9.94219 21.75 10.8281 21.75 12C21.75 13.1719 20.0794 14.0625 19.6238 15.1584C19.185 16.2197 19.7569 18.0319 18.8944 18.8944C18.0319 19.7569 16.2197 19.185 15.1584 19.6238C14.0625 20.0794 13.1719 21.75 12 21.75C10.8281 21.75 9.9375 20.0794 8.84156 19.6238C7.78125 19.185 5.96812 19.7569 5.10562 18.8944Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z"
          fill="black"
        />
        <path
          d="M12 13.5V12.75C13.6566 12.75 15 11.5744 15 10.125C15 8.67562 13.6566 7.5 12 7.5C10.3434 7.5 9 8.67562 9 10.125V10.5"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7531_12709">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WhoItsForIcon;
