const { useSearchParams } = require('react-router-dom');
const { createDocumentType } = require('utils/esign.helper');

const useCheckIsIamTheOnlySignerDocCreate = () => {
  const [searchParams] = useSearchParams();
  const createDocumentTypeParams = searchParams.get(createDocumentType.createDocumentType);
  const isIamTheOnlySigner = createDocumentType.IamTheOnlySigner === createDocumentTypeParams;
  return isIamTheOnlySigner;
};

export default useCheckIsIamTheOnlySignerDocCreate;
