import React, { useEffect, useState } from 'react';
import withPublicCommonHeader from '../withPublicCommonHeader';
import withBreadScumb from '../withBreadScumb';
import { getBlogsService, ProdDigiEsignBaseUrl } from 'services/eSignService';
import { useDispatch } from 'react-redux';
import { setEsignMainAppLoader } from 'store/esignMainSlice';
import { Card } from 'flowbite-react';

const BlogsList = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const getBlogList = async () => {
    try {
      dispatch(setEsignMainAppLoader(true));
      const data = await getBlogsService();
      const finalData = [];
      data?.data.forEach((item) => {
        item.sites.map((item2) => {
          if (item2?.url === ProdDigiEsignBaseUrl) {
            finalData.push(item);
          }
        });
      });
      setList(finalData);
      console.log('blogsData', finalData, data);
    } catch (error) {
      console.error(error, 'errordata');
    } finally {
      dispatch(setEsignMainAppLoader(false));
    }
  };
  useEffect(() => {
    getBlogList();
  }, []);

  return (
    <div>
      <div className="grid grid-cols-3 mt-[40px] gap-[50px]">
        {list.map((item) => {
          return (
            <Card className="max-w-sm" renderImage={() => <img width={500} height={500} src={item?.image} alt="image 1" />}>
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{item.title}</h5>
              {<div dangerouslySetInnerHTML={{ __html: item?.pageContent }} />}
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default withPublicCommonHeader(withBreadScumb(BlogsList, { title: 'Blogs' }));
