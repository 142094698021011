import FirstBannerLeftIcon from 'app/assets/icons/public_home/FirstBannerLeftIcon';
import FirstBannerRightIcon from 'app/assets/icons/public_home/FirstBannerRightIcon';
import { useScreenDetector } from 'app/hooks/useScreenDetector';
import React, { useEffect, useState } from 'react';
import { homeLandingPageRotateTextArray } from 'utils/esign.helper';
import StartFreeTrialButton from './StartFreeTrialButton';

const TopBanner = () => {
  const { isMobile, isTablet, isDesktop } = useScreenDetector();
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      const totalLength = homeLandingPageRotateTextArray.length;
      console.log(totalLength);
      setCurrentWordIndex((item) => {
        if (totalLength - 1 === item) {
          return 0;
        } else {
          return item + 1;
        }
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  console.log(currentWordIndex, 'currentWordIndex');
  return (
    <div className="mt-[8%] sm:mt-[70px] w-full">
      <div className="flex items-baseline sm:items-center  justify-evenly w-[100%] sm:w-[90%] mx-auto">
        <FirstBannerLeftIcon {...(isMobile ? { height: 'auto', width: '30%' } : {})} />
        <div className="flex flex-col items-center justify-center w-[100%] sm:w-[50%] pb-[5%]">
          <h4 className="text-[#030712] font-[700] text-[34px]  sm:text-[56px] text-center">eSignature solution for</h4>
          <h3 className="text-[#3B82F6] text-[34px] sm:text-[48px] font-[600]">{homeLandingPageRotateTextArray?.[currentWordIndex]}</h3>

          {/* <span class="text-[#3B82F6] text-[34px] sm:text-[48px] font-[600] inline-flex flex-col h-[calc(theme(fontSize.5xl)*theme(lineHeight.loose))] md:h-[calc(theme(fontSize.5xl)*theme(lineHeight.loose))] overflow-hidden">
            <ul class="block animate-text-slide-7 text-center leading-tight [&_li]:block">
              {homeLandingPageRotateTextArray.map((item) => {
                return (
                  <li aria-hidden="true" key={item} className="h-[70px]">
                    {item}
                  </li>
                );
              })}
               <li>Contracts</li>
              <li>Notices</li>
              <li>Litigation</li>
              <li>Regulatory</li>
              <li>Summons</li>

              <li>Petitions</li>
              <li>Compliance</li>
              <li>Banking</li>
              <li>Legal</li>
              <li>Finance</li>
              <li aria-hidden="true">Finance</li> 
            </ul>
          </span> */}

          <h5 className="text-[18px] sm:text-[30px] text-[#6B7280] font-[500] hidden sm:block mt-[10px]">Secure, Smart & Swift</h5>
          <div className="hidden sm:flex items-center gap-[10px] mt-[20px]">
            <StartFreeTrialButton />
            <button className="bg-[white] border-[2px] border-[#D1D5DB] rounded-[10px] h-[48px] px-[20px] text-[14px] font-[600] text-[black] w-fit mt-[20px]">
              Get a Demo
            </button>
          </div>
        </div>

        <FirstBannerRightIcon {...(isMobile ? { height: 'auto', width: '40%' } : {})} />
      </div>
      <h5 className="text-[24px] sm:text-[30px] text-[#6B7280] font-[500] text-center sm:hidden">Secure, Smart & Swift</h5>
      <div className="flex flex-col sm:hidden items-center gap-[8px] mb-[10%]">
        <StartFreeTrialButton />
        <button className="bg-[white] border-[2px] border-[#D1D5DB] rounded-[10px] h-[48px] px-[20px] text-[14px] font-[600] text-[black] w-full">
          Get a Demo
        </button>
      </div>
    </div>
  );
};

export default TopBanner;
