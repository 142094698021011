import { fontFamily, maxWidth } from '@mui/system';
import UploadSignIcon from 'app/assets/icons/UploadSignIcon';
import { fontFamilies } from 'app/pages/RoutesPages/AddSignOnCanvas/RightSideSignNPlaceholderValueAdd/SignActionsList/EsignFreeTextSign';
import classNames from 'classnames';
import { useAccount } from 'context/AccountProvider';
import { Tabs } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import PlaceHolderValueContainer from '../FabricCanvas/PlaceHolderValueContainer';
import html2canvas from 'html2canvas';
import { fabric } from 'fabric';
import { toast } from 'react-toastify';
import { uploadSignHelper } from 'utils/esign.helper';
const RemoveButton = ({ onClick, text = 'Remove' }) => {
  return (
    <button className="text-[14px] font-[500] text-[#FF3A3A]" onClick={onClick}>
      {text}
    </button>
  );
};
const UploadSignatureBody = ({ setFinalSignImage, finalSignImage }) => {
  const fileInputRef = useRef(null);
  // const handleImageUpload = (event) => {
  //   event.stopPropagation();
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setFinalSignImage(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleImageUpload = (event) => {
    // event.stopPropagation();
    // const file = event.target.files[0];

    // if (file) {
    //   const fileSizeInKB = file.size / 1024; // Convert bytes to kilobytes (KB)

    //   // Check if file size is within the required range
    //   if (fileSizeInKB < 30) {
    //     toast.error('File size is too small. Please upload an image larger than 30KB.');
    //     return;
    //   } else if (fileSizeInKB > 2048) {
    //     // 2MB is 2048KB
    //     toast.error('File size is too large. Please upload an image smaller than 2MB.');
    //     return;
    //   }

    //   const img = new Image();
    //   img.onload = () => {
    //     const width = img.width;
    //     const height = img.height;

    //     // Check dimensions
    //     if (width < 300 || width > 400 || height < 100 || height > 150) {
    //       toast.error('Invalid image dimensions. Please upload an image between 300-400px width and 100-150px height.');
    //       return;
    //     }

    //     setFinalSignImage(reader.result);
    //   };

    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     img.src = e.target.result; // Set image source to trigger onload
    //   };
    //   reader.readAsDataURL(file);
    //}
    uploadSignHelper(event, (result) => {
      setFinalSignImage(result);
    });
  };

  return (
    <>
      <div className="w-full border-[3px] border-dashed rounded-[10px] flex flex-col items-center justify-center gap-[12px] cursor-pointer h-[220px]">
        {!finalSignImage ? (
          <label htmlFor="file-upload" className="flex flex-col items-center justify-center gap-[12px]">
            <UploadSignIcon />
            <h4 className="text-[14px] font-[500] text-[#6B7280]">Upload your Signature</h4>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
          </label>
        ) : (
          <div className="h-[220px] w-full p-[10px] flex items-center justify-center flex-col gap-[10px]">
            <img src={finalSignImage} className="object-cover w-auto max-h-[150px]" alt="Signature" />
            <RemoveButton onClick={() => setFinalSignImage(null)}>Remove</RemoveButton>
          </div>
        )}
      </div>
    </>
  );
};

const DrawSignatureBody = ({ drawSignCanvas }) => {
  const handleClear = () => {
    drawSignCanvas?.current?.clear?.();
  };
  useEffect(() => {
    return () => {
      handleClear();
    };
  }, []);

  return (
    <>
      <div className="w-[580px] border-[3px] border-dashed rounded-[10px] flex flex-col items-center justify-center gap-[12px] cursor-pointer h-[220px] relative pb-[10px]">
        <SignaturePad
          penColor={'black'}
          canvasProps={{ className: 'sigCanvas relative', height: 180, width: '580px' }}
          ref={drawSignCanvas}
        />

        <p className="text-[14px] font-[500] text-[#6B7280] absolute left-[10px] bottom-[10px]">Draw your Signature here</p>
        <RemoveButton onClick={handleClear} text="Clear Canvas" />
      </div>
    </>
  );
};

const StylizeSignatureBody = ({ setFinalSignImage }) => {
  const { userDetails } = useAccount();
  const [selectedItem, setSelectedItem] = useState(null);
  const fullName = `${userDetails.first_name || ''} ${userDetails.last_name || ''}`;
  console.log(fullName, userDetails, 'userDetails');
  const handleCapture = (id) => {
    const captureElement = document.getElementById(id);
    html2canvas(captureElement, {
      height: 55,
      scale: 50,
      backgroundColor: 'transparent' // Optional: change background color,
    }).then((canvas) => {
      // Convert canvas to data URL
      const imgData = canvas.toDataURL('image/png');
      console.log(imgData, 'imgData');
      setFinalSignImage(imgData);
    });
  };

  return (
    <>
      <div className="w-full">
        {fontFamilies.map((item, i) => {
          const id = `styleSign_${item?.id}`;
          return (
            <div
              key={item.id}
              className={classNames(
                'h-[35px]  text-[22px]  font-[400] text-[#101010] px-[20px] py-[10px]  flex flex-col items-start justify-center border-x-[1px] border-b-[1px] hover:bg-[#3B82F6] hover:text-[white] cursor-pointer ',
                {
                  'bg-[#3B82F6] text-[white]': selectedItem?.id === item.id
                },
                { 'rounded-[6px] rounded-b-none border-t-[1px]': i === 0 },
                { 'rounded-[6px] rounded-t-none': i === fontFamilies.length - 1 }
              )}
              onClick={() => {
                setSelectedItem(item);
                handleCapture(id);
              }}
            >
              <div
                style={{
                  fontFamily: item.type,
                  fontSize: '22px'
                }}
                className="freeTextSignTextPreview  h-[35px]"
                id={id}
              >
                {fullName}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const TabList = [
  { title: 'Upload Sign', type: 'upload_sign', Component: UploadSignatureBody },
  { title: 'Draw Sign', type: 'draw_sign', Component: DrawSignatureBody },
  { title: 'Stylize Sign', type: 'stylize_sign', Component: StylizeSignatureBody }
];
export function CreateYouSignatureTabs({ setFinalSignImage, finalSignImage, drawSignCanvas, setActiveSignTab }) {
  return (
    <Tabs aria-label="Tabs with icons" variant="underline" theme={theme} onActiveTabChange={(tab) => setActiveSignTab(tab)}>
      {TabList.map((item) => {
        return (
          <Tabs.Item key={item.type} active title={item.title} id="newTabVks">
            <item.Component setFinalSignImage={setFinalSignImage} finalSignImage={finalSignImage} drawSignCanvas={drawSignCanvas} />
          </Tabs.Item>
        );
      })}
    </Tabs>
  );
}

const theme = {
  base: 'flex flex-col gap-2',
  tablist: {
    base: 'flex text-center',
    variant: {
      default: 'flex-wrap border-gray-200 dark:border-gray-700',
      underline: '-mb-px flex-wrap border-gray-200 dark:border-gray-700',
      pills: 'flex-wrap space-x-2 text-sm font-medium text-gray-500 dark:text-gray-400',
      fullWidth:
        'grid w-full grid-flow-col divide-x divide-gray-200 rounded-none text-sm font-medium shadow dark:divide-gray-700 dark:text-gray-400'
    },
    tabitem: {
      base: 'flex items-center justify-center px-[10px] pb-[16px] text-[14px] font-[600] focus:outline-none disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500',
      variant: {
        default: {
          base: 'rounded-t-lg',
          active: {
            on: 'bg-gray-100 text-[#3B82F6] dark:bg-[#3B82F6] dark:text-[#3B82F6]',
            off: 'text-gray-500 hover:bg-gray-50 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-[#3B82F6] dark:hover:text-gray-300'
          }
        },
        underline: {
          base: 'rounded-t-lg',
          active: {
            on: 'active rounded-t-lg border-b-2 border-[#3B82F6] text-[#3B82F6] dark:border-[#3B82F6] dark:text-[#3B82F6]',
            off: 'border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300'
          }
        },
        pills: {
          base: '',
          active: {
            on: 'rounded-lg bg-[#3B82F6] text-white',
            off: 'rounded-lg hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-white'
          }
        },
        fullWidth: {
          base: 'ml-0 flex w-full rounded-none first:ml-0',
          active: {
            on: 'active rounded-none bg-gray-100 p-4 text-gray-900 dark:bg-gray-700 dark:text-white',
            off: 'rounded-none bg-white hover:bg-gray-50 hover:text-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white'
          }
        }
      },
      icon: 'mr-2 h-5 w-5'
    }
  },
  tabitemcontainer: {
    base: '',
    variant: {
      default: '',
      underline: '',
      pills: '',
      fullWidth: ''
    }
  },
  tabpanel: ''
};
