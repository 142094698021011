import BlackLogo from 'app/assets/icons/BlackLogo';
import React from 'react';
const FooterCompany = ['Terms Of Use', 'Privacy Policy', 'About US'];

const FooterSupport = ['Pricing', 'Tech Support', 'Sales Desk', 'FAQs'];
const FooterSolutions = ['Contracts', 'Practice Management', 'Document Automation', 'Legal Research', 'Analytics', 'Marketplace'];
const FooterResources = ['Contract Library', 'Clause Library', 'Case Studies', 'Whitepapers', 'Blogs'];
const PublicFooter = () => {
  return (
    <div className="mt-[70px]  bg-[white] w-full  flex-col items-center  sm:flex sm:px-[0px] sm:py-[0px] px-[16px] py-[24px]">
      <div className="sm:w-[90%] w-full flex flex-col justify-center items-center pt-[30px] ">
        <div className="w-full flex items-start justify-around flex-wrap ">
          <div className="w-full sm:w-[30%]">
            <BlackLogo />
            <h4 className="text-[#000000] font-[700] text-[14px] mt-[13px] mb-[12px]">Digiebooks Private Limited</h4>
            <p className="text-[#6B7280] font-[400] text-[14px] w-[300px]">
              Lower operational costs by eliminating the need for physical document handling
            </p>
          </div>
          <div className="w-full sm:w-[55%] flex justify-between sm:mt-[0px] mt-[24px] flex-wrap sm:gap-[0px] h-auto">
            <div className="flex flex-col items-start  gap-[10px] sm:w-fit w-[50%] ">
              <h3 className="font-[700] text-[14px] text-[#030712]">Company</h3>
              {FooterCompany.map((item) => {
                return (
                  <h3 key={item} className="font-[400] text-[14px] text-[#6B7280]">
                    {item}
                  </h3>
                );
              })}
            </div>
            <div className="flex flex-col items-start justify-start gap-[10px] sm:w-fit w-[32%]">
              <h3 className="font-[700] text-[14px] text-[#030712]">Support</h3>
              {FooterSupport.map((item) => {
                return (
                  <h3 key={item} className="font-[400] text-[14px] text-[#6B7280]">
                    {item}
                  </h3>
                );
              })}
            </div>
            <div className="flex flex-col items-start justify-start gap-[10px] sm:w-fit w-[50%] sm:mt-[0px] mt-[25px]">
              <h3 className="font-[700] text-[14px] text-[#030712]">Solutions</h3>
              {FooterSolutions.map((item) => {
                return (
                  <h3 key={item} className="font-[400] text-[14px] text-[#6B7280]">
                    {item}
                  </h3>
                );
              })}
            </div>
            <div className="flex flex-col items-start justify-start gap-[10px] sm:w-fit w-auto sm:mt-[0px] mt-[25px]">
              <h3 className="font-[700] text-[14px] text-[#030712] w-full ">Resources</h3>
              {FooterResources?.map((item) => {
                return (
                  <h3 key={item} className="font-[400] text-[14px] text-[#6B7280] w-full ">
                    {item}
                  </h3>
                );
              })}
            </div>
          </div>
        </div>
        <div className="border-t-[1px] border-[#7C7C7C] mt-[32px] w-full flex items-center justify-center pt-[20px]">
          <p className="text-[14px] font-[400] text-[#71717A] pb-[20px]">
            © Copyright 2024, All Rights Reserved by DigiEbook Solutions Pvt. Ltd.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PublicFooter;
