import React from 'react';
import EsignCanvasAddText from './EsignCanvasAddText';

const EsignAddName = ({ ...props }) => {
  return (
    <>
      <EsignCanvasAddText label={'Name'} {...props} />
    </>
  );
};

export default EsignAddName;
