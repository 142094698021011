import React, { useState } from 'react';
import EsignCanvasAddText from './EsignCanvasAddText';

const EsignAddInitials = ({ ...props }) => {
  return (
    <>
      <EsignCanvasAddText label={'Initials'} {...props} />
    </>
  );
};

export default EsignAddInitials;
