import EsignAppAssets from 'app/assets';
import BellWhyChooseEsignIcon from 'app/assets/icons/public_home/BellWhyChooseEsignIcon';
import BuildingWhyChooseEsignIcon from 'app/assets/icons/public_home/BuildingWhyChooseEsignIcon';
import FileTextWhyChooseEsignIcon from 'app/assets/icons/public_home/FileTextWhyChooseEsignIcon';
import FileWhyChooseEsignIcon from 'app/assets/icons/public_home/FileWhyChooseEsignIcon';
import GearWhyChooseEsignIcon from 'app/assets/icons/public_home/GearWhyChooseEsignIcon';
import LockWhyChooseEsignIcon from 'app/assets/icons/public_home/LockWhyChooseEsignIcon';
import MoreButtonnIcon from 'app/assets/icons/public_home/MoreButtonnIcon';
import PathWhyChooseEsignIcon from 'app/assets/icons/public_home/PathWhyChooseEsignIcon';
import ShieldWhyChooseEsignIcon from 'app/assets/icons/public_home/ShieldWhyChooseEsignIcon';
import SignWhyChooseIcon from 'app/assets/icons/public_home/SignWhyChooseIcon';
import { useScreenDetector } from 'app/hooks/useScreenDetector';
import React, { useEffect, useState } from 'react';
import StartFreeTrialButton from './StartFreeTrialButton';
const DigiEsignFeature = [
  { title: 'Automate Workflows', Icon: GearWhyChooseEsignIcon },
  { title: 'Send Automatic Reminders', Icon: FileWhyChooseEsignIcon },
  { title: 'Upload, draw or Stylize your Signature', Icon: SignWhyChooseIcon },
  { title: 'Industry specific templates', Icon: BuildingWhyChooseEsignIcon },
  { title: 'Send Documents to multiple recipients simultaneously', Icon: FileTextWhyChooseEsignIcon },
  { title: 'Ensure Data security and compliance', Icon: LockWhyChooseEsignIcon },
  { title: 'Legally enforceable Aadhar authenticated signatures.', Icon: ShieldWhyChooseEsignIcon },
  { title: 'Comprehensive Audit Trails of every document', Icon: PathWhyChooseEsignIcon },
  { title: 'Real Time notification of Status and approvals', Icon: BellWhyChooseEsignIcon }
];
const WhyChooseDigieSignSection = () => {
  const [digiesignFeatures, setDigiesignFeatures] = useState(DigiEsignFeature.slice(0, 5));
  const { isMobile, isDesktop } = useScreenDetector();
  useEffect(() => {
    if (isDesktop) {
      setDigiesignFeatures(DigiEsignFeature);
    }
  }, [isDesktop]);

  return (
    <div className="bg-[#DCEEFA] rounded-[10px]  flex  items-center justify-center h-[100%] px-[16px] sm:py-[70px] py-[30px]">
      <div className="sm:w-[60%] max-h-[50%]">
        <div className="">
          <h4 className="text-[#1D4ED8] text-[12px] font-[700] ">BE READY TO GET MORE</h4>
          <h2 className="text-[#030712] text-[24px] sm:text-[44px] font-[800] max-w-[400px] mt-[4px] sm:mt-[0px] mb-[36px]">
            Why choose DigieSign
          </h2>
        </div>
        <ul className="grid sm:grid-cols-2 ">
          {digiesignFeatures.map(({ title, Icon }) => {
            return (
              <>
                <li className="text-[#030712] text-[16px] font-[500] mb-[16px] sm:mb-[32px] flex items-center gap-[10px] max-w-[400px]">
                  <span>
                    <Icon />
                  </span>
                  {title}
                </li>
              </>
            );
          })}
          <button className=" sm:hidden text-[#3B82F6] text-[14px] font-[500] flex items-center justify-center gap-[6px] mb-[5%]">
            <h4>More</h4>
            <h4>
              <MoreButtonnIcon />
            </h4>
          </button>
        </ul>
        <StartFreeTrialButton />
      </div>
      <div className="sm:w-[30%] items-center justify-end hidden sm:flex">
        <img src={EsignAppAssets.WhyChooseDigiEsignImage} />
      </div>
    </div>
  );
};

export default WhyChooseDigieSignSection;
