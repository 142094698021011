import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import CrossIcon from 'app/assets/icons/CrossIcon';
import classNames from 'classnames';
import { getDocumentById, getNotifications, getRecipientStatus } from 'services/eSignService';
import { useDispatch } from 'react-redux';
import { setEsignMainAppLoader } from 'store/esignMainSlice';
import { formatDateTime, getUserFullNameInitials, RecipientDropdownActionsConst } from 'utils/esign.helper';
import { useAccount } from 'context/AccountProvider';
import { AuditTrialTimeline } from './AuditTrialTimeline';

const filterDataByDate = (items, email) => {
  // Get current date and date 7 days ago
  const now = new Date();
  const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

  // Initialize categories
  const result = {
    last7Days: [],
    older: []
  };

  // Iterate through items and classify them
  items.forEach((item) => {
    const itemDate = new Date(item.date);
    const newItem = {
      senderName:
        item?.role === 'organizer' || item?.status === RecipientDropdownActionsConst.DocumentSent
          ? item?.sender_email === email
            ? 'You'
            : item?.sender_name
          : item?.recipients?.[0]?.email === email
          ? 'You'
          : item?.recipients?.[0]?.name,
      documentName: item?.document_name.split('.')?.[0],
      notificationDate: formatDateTime(item.date)?.date,
      senderInitials: getUserFullNameInitials(item.sender_name),
      statusValue: item.status ? item.status : 'No Status',
      role: item.role
    };
    if (itemDate >= sevenDaysAgo) {
      result.last7Days.push(newItem);
    } else {
      result.older.push(newItem);
    }
  });
  return result;
};
export default function AuditTrialDrawer({ onClose, documentId }) {
  const dispatch = useDispatch();
  const [notificationData, setNotificationData] = React.useState([]);
  const { userDetails } = useAccount();

  const getNotificationData = async () => {
    try {
      // const data = await getNotifications((value) => {
      //   dispatch(setEsignMainAppLoader(value));
      // });
      const data = await getRecipientStatus(documentId, (value) => dispatch(setEsignMainAppLoader(value)));
      const {
        data: { data: documentData }
      } = await getDocumentById(documentId);
      const currentUser = data?.filter((item) => item?.email === userDetails?.email)?.[0] || {};

      const documentCreatedAt = documentData?.created_at?.$date;
      console.log(data, userDetails, userDetails?.email, currentUser, documentData, documentCreatedAt, 'currentUser');
      const auditData = [
        {
          name: userDetails?.first_name || '' + userDetails?.last_name || '',
          email: userDetails?.email,
          description: 'Created the document',
          date: documentCreatedAt
        }
      ];
      const allRecipients = data?.filter((item) => item?.email !== userDetails?.email);

      if (allRecipients?.length) {
        let sharedTheDocumentWith = '';
        allRecipients?.map((item) => {
          sharedTheDocumentWith += `${item?.name}, `;
        });
        auditData.push({
          name: userDetails?.first_name || '' + userDetails?.last_name || '',
          email: userDetails?.email,
          description: `shared the document with ${sharedTheDocumentWith}`,
          date: documentCreatedAt
        });
      }
      data?.map((item) => {
        auditData.push({
          name: item?.name,
          email: item?.email,
          status: item?.status,
          date: item?.status_date
        });
      });
      console.log(data, auditData, 'audit trial data');
      setNotificationData(auditData);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (userDetails?.email) {
      getNotificationData();
    }
  }, [userDetails?.email]);
  console.log(notificationData, 'notificationData');
  const list = (anchor) => (
    <Box sx={{ width: '100vw', minWidth: '300px', maxWidth: '600px' }} role="presentation">
      <div className="w-full  flex flex-col items-start justify-center p-[20px] ">
        <div className="w-full h-[100%] flex items-center justify-between pt-[8px] pb-[20px] ">
          <h2 className="font-[700] text-[16px] text-[#101010]">Audit Trail</h2>
          <button onClick={onClose}>
            <CrossIcon />
          </button>
        </div>
        <div className="flex flex-col items-center justify-center px-[20px] w-full">
          <AuditTrialTimeline data={notificationData} documentId={documentId} />
        </div>
      </div>
    </Box>
  );

  const anchor = 'right';
  return (
    <div>
      <Drawer anchor={anchor} open={true} onClose={onClose}>
        {list('right')}
      </Drawer>
    </div>
  );
}
