import axios from 'axios';

const token = localStorage.getItem('token');

export const getAllUsers = async () => {
  try {
    const { data } = await axios.get(`users`);
    return data;
  } catch (err) {
    return err;
  }
};
export const createUser = async (payload) => {
  try {
    const { data } = await axios.post(`users/`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const addOrganization = async (username, payload) => {
  try {
    const { data } = await axios.patch(`users/${username}`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const addSubscription = async (userId, payload) => {
  try {
    const { data } = await axios.post(`users/${userId}/subscriptions`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
// export const getUserSubscription = async (userId, payload) => {
//     try {
//         const { data } = await axios.get(`users/${userId}/subscriptions`, payload);
//         return data;
//     } catch (err) {
//         return err;
//     }
// };
export const getMyUserDetails = async () => {
  try {
    return fetch('https://apis.digiebooks.in/users/my-details', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then((res) => res.json());
  } catch (err) {
    console.log(err, 'errorPopData');
    return err;
  }
};
export const getUserPlansDetails = async () => {
  try {
    return fetch('https://apis.digiebooks.in/plans', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then((res) => res.json());
  } catch (err) {
    return err;
  }
};
export const getUserSubscription = async () => {
  try {
    return fetch('https://apis.digiebooks.in/subscriptions', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then((res) => res.json());
  } catch (err) {
    return err;
  }
};
export const getUsersDetails = async (id) => {
  try {
    return fetch(`https://apis.digiebooks.in/organisations/${id}/list-users`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then((res) => res.json());
  } catch (err) {
    return err;
  }
};

export const createUserOrganization = async (payload) => {
  try {
    const { data } = await axios.post(`users/organisations`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const assignProductToUser = async (userId, productId, payload) => {
  try {
    const { data } = await axios.post(`users/${userId}/assigned-products/${productId}`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const deassignUserFromProduct = async (userId, productId) => {
  try {
    const { data } = await axios.delete(`users/${userId}/assigned-products/${productId}`);
    return data;
  } catch (err) {
    return err;
  }
};
export const changeUserStatus = async (userId, payload) => {
  try {
    const { data } = await axios.post(`users/${userId}/change-user-status`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const deleteUser = async (userId) => {
  try {
    const { data } = await axios.delete(`users/${userId}/delete-user`);
    return data;
  } catch (err) {
    return err;
  }
};
