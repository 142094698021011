import LaptopIcon from 'app/assets/icons/internal_pages/LaptopIcon';

export const DocumentDeliveryTrackingInteraction = [
  {
    title: 'Physical Delivery',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/document-delivery-&-tracking/physical-delivery'
  },
  {
    title: 'Digital Distribution',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/document-delivery-&-tracking/digital-distribution'
  },
  {
    title: 'Real Time Tracking ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/document-delivery-&-tracking/real-time-tracking'
  },
  {
    title: 'Reporting & Analytics',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/document-delivery-&-tracking/reporting-&-analytics'
  },
  {
    title: 'Compliance & Audit',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/document-delivery-&-tracking/compliance-&-audit'
  },
  {
    title: 'Automated Follow Up',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/document-delivery-&-tracking/automated-follow-up'
  }
];

export const PhysicalDeliveryList = [
  {
    title: 'Courier Services Integration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/document-delivery-&-tracking/physical-delivery/courier-services-integration'
  },
  {
    title: 'Postal Services',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/document-delivery-&-tracking/physical-delivery/postal-services'
  }
];

const CourierServicesIntegrationList = [
  {
    title: 'Multiple vendor options',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Rate optimization ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Real-time pickup scheduling ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Priority delivery options ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const PostalServicesList = [
  {
    title: 'Registered Post (Acknowledgment/Due) ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Speed Post tracking ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Bulk mailing capability ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Address verification',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const DigitalDistributionList = [
  {
    title: 'Electronic Channels',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/document-delivery-&-tracking/digital-distribution/electronic-channels'
  }
];

const ElectricalChannelsList = [
  {
    title: 'Email delivery with tracking  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'SMS notifications ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'WhatsApp Business integration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Secure download links ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },

  {
    title: 'Digital acknowledgments ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Dashboard access to confirm the status of the delivery',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const RealtimeTrackingList = [
  {
    title: 'Delivery Monitoring',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/document-delivery-&-tracking/real-time-tracking/delivery-monitoring'
  },
  {
    title: 'Proof of Delivery (PoD)',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/document-delivery-&-tracking/real-time-tracking/proof-of-delivery'
  }
];

const DeliveryMonitoringList = [
  {
    title: 'Live status updates',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'GPS tracking for physical delivery ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Digital read receipts ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Delivery attempt logs ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },

  {
    title: 'Exception notifications ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const ProofOfDeliveryList = [
  {
    title: 'Automated PoD generation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Digital acknowledgments ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Signature capture ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Photo documentation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },

  {
    title: 'Timestamp verification ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const ReportingAnalyticsList = [
  {
    title: 'MIS Reporting ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/document-delivery-&-tracking/reporting-&-analytics/mis-reporting'
  },
  {
    title: 'Export Capabilities ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/document-delivery-&-tracking/reporting-&-analytics/export-capabilities'
  }
];

const MISReportingList = [
  {
    title: 'Customizable dashboards ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Multiple filter options ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Delivery success rates ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'TAT analysis ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },

  {
    title: 'Cost analytics ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const ExportCapabilitiesList = [
  {
    title: 'Multiple format options ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Scheduled reports ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Custom field selection ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Batch processing ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },

  {
    title: 'Data visualization ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const ComplianceAuditList = [
  {
    title: 'Audit Management',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/document-delivery-&-tracking/compliance-&-audit/audit-management'
  }
];

const AuditManagementList = [
  {
    title: 'Complete delivery trails ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Document journey tracking ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Access logs ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Status change history ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },

  {
    title: 'Compliance documentation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const AutomatedFollowUpList = [
  {
    title: 'Action Management',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/document-delivery-&-tracking/automated-follow-up/action-management'
  }
];

const ActionManagementList = [
  {
    title: 'Automated reminders  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Escalation workflows',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'SLA monitoring ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Priority flagging ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },

  {
    title: 'Exception handling ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

/// ---------------- Integration pages-----------------

const PowerfulIntegrationForSeamlessWorkflowsList = [
  {
    title: 'Lexops Platform Integration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/lexops-platform-integration'
  },
  {
    title: 'Enterprise-Grade Integration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/enterprise-grade-integration'
  },
  {
    title: 'Seamless Microsoft Integration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/seamless-microsoft-integration'
  },
  {
    title: 'Additional Integrations',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/additional-integrations'
  },
  {
    title: 'Compliance Database Integrations',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/compliance-database-integration'
  },
  {
    title: 'Regulatory Database Integrations  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/regulatory-database-integrations'
  },
  {
    title: 'Additional Features ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/additional-features'
  }
];

const LexopsPlatformIntegrationList = [
  {
    title: 'Full compliance with IT Act 2000 ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Digital signatures with legal validity',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Court-admissible audit trails with detailed timestamps',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Integration with licensed Digital Signature Certificates (DSC) ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'eSign API for paperless transactions ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Complete GDPR compliance framework',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Secure data storage within Indian territory ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Real-time legal validity checks ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const EnterpriseGradeIntegrationList = [
  {
    title: 'Comprehensive REST API ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/enterprise-grade-integration/comprehensive-rest-api'
  },
  {
    title: 'Authentication & Security ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/enterprise-grade-integration/authentication-&-security'
  },
  {
    title: 'Advanced Logging ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/enterprise-grade-integration/advanced-logging'
  }
];
const ComprehensiveRestAPIList = [
  {
    title: 'Custom implementation support ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Secure API endpoints',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Detailed documentation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Development sandbox ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const AuthenticationSecurityList = [
  {
    title: 'Single Sign-On (SSO) support',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Active Directory integration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Multi-factor authentication',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Role-based access control ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const AdvancedLoggingList = [
  {
    title: 'Detailed audit trails ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'User activity monitoring ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Security event logging',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Compliance reporting ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const SeamlessMicrosoftIntegrationList = [
  {
    title: 'Word Addin',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/seamless-microsoft-integration/word-addin'
  },
  {
    title: 'Sharepoint Connection',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/seamless-microsoft-integration/sharepoint-connection'
  },
  {
    title: 'Team Integration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/seamless-microsoft-integration/team-integration'
  }
];

const WordAddinList = [
  {
    title: 'Sign Documents directly in Word',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Access Templates without switching apps',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Track changes and versions',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const SharepointConnectionList = [
  {
    title: 'Direct Document Storage and retrieval',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automatic File Organisation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Version control Integration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const TeamIntegrationList = [
  {
    title: 'Real Time Collaboration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Document sharing and Tracking',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const AdditionalIntergrationList = [
  {
    title: 'Aadhar Integration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/additional-integrations/aadhar-integration'
  },
  {
    title: 'API Protocol Support',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/additional-integrations/api-protocol-support'
  },
  {
    title: 'Contract Lifecycle Management',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integration/additional-integrations/contract-lifecycle-management'
  }
];

const AadharIntegrationList = [
  {
    title: 'eKYC verification',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'OTP Validation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Instant Identity Verification',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const APIProtocolSupport = [
  {
    title: 'Open Network Compatability',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Decentralised Commerce Support',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Standardised Interfaces',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Interoperable Systems',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const ContractLifecycleManagement = [
  {
    title: 'Automated Contract Creation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Lifecycle Tracking ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Renewal Management',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Risk assessment Tools',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const ComplianceDatabaseIntegrationsList = [
  {
    title: 'Real time Compliance Checks',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'PAN Database Verification',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'MCA Database Connection',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Bank Account Validation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const RegulatoryDatabaseIntegrationsList = [
  {
    title: 'RBI Database Access',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'SEBI Integration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Insurance Regulatory checks',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Legal Entity Verification',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const AdditionalFeaturesList = [
  {
    title: 'Real time API access to all databses',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automated Compliance Allerts',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Regular database updates',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Custom Validation rules',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Detailed verification reports',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Audit Trail for all checks',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

export const AllExploreSlugList = {
  '/document-delivery-&-tracking': {
    title: 'Document Delivery & Tracking',
    description: `Transform your document delivery process with DigiESign's comprehensive physical and digital delivery management system. We offer the following service options.`,
    list: DocumentDeliveryTrackingInteraction
  },
  '/document-delivery-&-tracking/physical-delivery': {
    title: 'Physical Delivery',
    description: `Transform your document delivery process with DigiESign's comprehensive physical and digital delivery management system. We offer the following service options.`,
    list: PhysicalDeliveryList
  },
  '/document-delivery-&-tracking/physical-delivery/courier-services-integration': {
    title: 'Courier Services Integration',
    description: ``,
    list: CourierServicesIntegrationList,
    isChild: true
  },
  '/document-delivery-&-tracking/physical-delivery/postal-services': {
    title: 'Postal Services',
    description: ``,
    list: PostalServicesList,
    isChild: true
  },
  '/document-delivery-&-tracking/digital-distribution': {
    title: 'Digital Distribution',
    description: ``,
    list: DigitalDistributionList
  },
  '/document-delivery-&-tracking/digital-distribution/electronic-channels': {
    title: 'Electronic Channels',
    description: ``,
    list: ElectricalChannelsList,
    isChild: true
  },
  '/document-delivery-&-tracking/real-time-tracking': {
    title: 'Real Time Tracking',
    description: ``,
    list: RealtimeTrackingList
  },
  '/document-delivery-&-tracking/real-time-tracking/delivery-monitoring': {
    title: 'Delivery Monitoring',
    description: ``,
    list: DeliveryMonitoringList,
    isChild: true
  },
  '/document-delivery-&-tracking/real-time-tracking/proof-of-delivery': {
    title: 'Proof of Delivery (PoD)',
    description: ``,
    list: ProofOfDeliveryList,
    isChild: true
  },
  '/document-delivery-&-tracking/reporting-&-analytics': {
    title: 'Reporting & Analytics',
    description: ``,
    list: ReportingAnalyticsList
  },
  '/document-delivery-&-tracking/reporting-&-analytics/mis-reporting': {
    title: 'MIS Reporting',
    description: ``,
    list: MISReportingList,
    isChild: true
  },
  '/document-delivery-&-tracking/reporting-&-analytics/export-capabilities': {
    title: 'Export Capabilities',
    description: ``,
    list: ExportCapabilitiesList,
    isChild: true
  },
  '/document-delivery-&-tracking/compliance-&-audit': {
    title: 'Compliance & Audit',
    description: ``,
    list: ComplianceAuditList
  },
  '/document-delivery-&-tracking/compliance-&-audit/audit-management': {
    title: 'Audit Management',
    description: ``,
    list: AuditManagementList,
    isChild: true
  },
  '/document-delivery-&-tracking/automated-follow-up': {
    title: 'Automated Follow Up',
    description: ``,
    list: AutomatedFollowUpList
  },
  '/document-delivery-&-tracking/automated-follow-up/action-management': {
    title: 'Action Management',
    description: ``,
    list: ActionManagementList,
    isChild: true
  },
  '/integration': {
    title: 'Powerful Integrations for Seamless Workflows ',
    description: `Over 70+ integration with legal, regulatory & compliance tools`,
    list: PowerfulIntegrationForSeamlessWorkflowsList
  },
  '/integration/lexops-platform-integration': {
    title: 'Lexops Platform Integration',
    description: ``,
    list: LexopsPlatformIntegrationList,
    isChild: true
  },
  '/integration/enterprise-grade-integration': {
    title: 'Enterprise-Grade Integration',
    description: ``,
    list: EnterpriseGradeIntegrationList
  },
  '/integration/enterprise-grade-integration/comprehensive-rest-api': {
    title: 'Comprehensive REST API',
    description: ``,
    list: ComprehensiveRestAPIList,
    isChild: true
  },
  '/integration/enterprise-grade-integration/authentication-&-security': {
    title: 'Authentication & Security ',
    description: ``,
    list: AuthenticationSecurityList,
    isChild: true
  },
  '/integration/enterprise-grade-integration/advanced-logging': {
    title: 'Advanced Logging',
    description: ``,
    list: AdvancedLoggingList,
    isChild: true
  },
  '/integration/seamless-microsoft-integration': {
    title: 'Seamless Microsoft Integration',
    description: ``,
    list: SeamlessMicrosoftIntegrationList
  },
  '/integration/seamless-microsoft-integration/word-addin': {
    title: 'Word Addin',
    description: ``,
    list: WordAddinList,
    isChild: true
  },
  '/integration/seamless-microsoft-integration/sharepoint-connection': {
    title: 'Sharepoint Connection',
    description: ``,
    list: SharepointConnectionList,
    isChild: true
  },
  '/integration/seamless-microsoft-integration/team-integration': {
    title: 'Team Integration',
    description: ``,
    list: TeamIntegrationList,
    isChild: true
  },
  '/integration/additional-integrations': {
    title: 'Additional Integrations',
    description: ``,
    list: AdditionalIntergrationList
  },
  '/integration/additional-integrations/aadhar-integration': {
    title: 'Aadhar Integration',
    description: ``,
    list: AadharIntegrationList,
    isChild: true
  },
  '/integration/additional-integrations/api-protocol-support': {
    title: 'API Protocol Support',
    description: ``,
    list: APIProtocolSupport,
    isChild: true
  },
  '/integration/additional-integrations/contract-lifecycle-management': {
    title: 'Contract Lifecycle Management (CLM)',
    description: ``,
    list: ContractLifecycleManagement,
    isChild: true
  },
  '/integration/compliance-database-integration': {
    title: 'Compliance Database Integrations',
    description: ``,
    list: ComplianceDatabaseIntegrationsList,
    isChild: true
  },
  '/integration/regulatory-database-integrations': {
    title: 'Regulatory Database Integrations',
    description: ``,
    list: RegulatoryDatabaseIntegrationsList,
    isChild: true
  },
  '/integration/additional-features': {
    title: 'Additional Features',
    description: ``,
    list: AdditionalFeaturesList,
    isChild: true
  }
};
