import React, { useState } from 'react';
import EsignAppPrimaryButton from './EsignAppPrimaryButton';
import { useNavigate } from 'react-router';
import { StartESignWorkflowModal } from '../Popups/StartESignWorkflowModal';

const StartEsignWorkflow = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <StartESignWorkflowModal open={openModal} onClose={() => setOpenModal(false)} />
      <EsignAppPrimaryButton
        title="Start E-Sign Workflow"
        onClick={() => {
          // navigate('/dashboard/create-document');
          setOpenModal(true);
        }}
      />
    </>
  );
};

export default StartEsignWorkflow;
